import styled from "styled-components";


export const AdvertAffix = styled.div`
  position: fixed;
  left: 0;
  bottom: 120px;
  width: 120px;
  height: 72px;
  z-index: 10;
  .rv-floating-ball__base {
    width: 100%;
    height: 100%;
  }
`

export const Layout = styled.div`
  width: 100%;
  height: 100%;
`


export const AdvertImage = styled.img`
  width: 100%;
  height: 100%;
`;
