

// const LOCATION_MARKER = `https://o.i-firefly.com/app/location.40x68.png`;
import type {ISize} from "@stage/math";
import {Fits} from "@crt/math";
import {LngLat} from "../typing/index";

// const LOCATION_MARKER = `https://o.i-firefly.com/app/location-40.png`;

export function buildStaticMapUrl(
    location: LngLat,
    size: ISize,
    zoom: number = 10
) {
    let width = Math.max(Math.round(size.width), 50);
    let height = Math.max(Math.round(size.height), 50);
    if (width > 1680 || height > 1200) {
        const fit = Fits.contain({ width, height }, { width: 1680, height: 1200 });
        width = fit.frame.width;
        height = fit.frame.height;
    }
    const sizeValue = `${width}*${height}`;
    const center = `${location.lat},${location.lng}`;
    const zoomValue = Math.min(Math.max(Math.round(zoom), 4), 18);

    return `https://a.i-firefly.com/staticmap/${center}/${sizeValue}/zoom=${zoomValue}&scale=2&centerMarker=true`;
}

