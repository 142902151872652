import {
    Document,
    ViewSGElement,
    isDocument,
    isElementDocument,
    isImageDocument,
    isMaternalDocument,
    isPageDocument,
    isPageLayoutDocument,
    Layout,
    PageLayout,
    SGElement,
    isGroupElement
} from "../document";
import {BackgroundProperties} from "../../typing";
import {
    Visitor as NodeVisitor,
    visitEachChild,
    VisitEachChildFunction,
    visitNode,
    visitNodes,
    VisitResult,
    NodesVisitor
} from "../../node";
import { Kind } from "../../typing";



// export namespace NodeVisitors {
//
//
//
//     // export function visitNode<N>(node: N, visitor: Visitor2<N>, eachChildFn: VisitEachChildFunction<N>): N | undefined {
//     //     function nodesVisitor(nodes: Array<N>): Array<N> {
//     //         return visitNodes(nodes, visitor, eachChildFn);
//     //     }
//     //     function nodeVisitor(node: N): N | undefined {
//     //         if (node === undefined) {
//     //             return undefined as N;
//     //         }
//     //         let visitedNode: N | undefined = visitor(node);
//     //         if (visitedNode) {
//     //             visitedNode = eachChildFn(visitedNode, visitor, nodesVisitor, nodeVisitor);
//     //         }
//     //         return visitedNode
//     //     }
//     //     return nodeVisitor(node)!;
//     // }
//     //
//     // export function visitNodes<N>(
//     //     nodes: Array<N>,
//     //     visitor: Visitor2<N>,
//     //     eachChildFn: VisitEachChildFunction<N>
//     // ): Array<N> {
//     //     if (nodes === undefined) {
//     //         // If the input type is undefined, then the output type can be undefined.
//     //         return nodes;
//     //     }
//     //
//     //     const updated = visitArray(nodes, visitor, 0, nodes.length)
//     //
//     //     return updated;
//     //
//     //     // const visitedNodes = nodes.map((node) => {
//     //     //     return visitNode(node, visitor, eachChildFn);
//     //     // })
//     //     // if (nodes.some((child, i) => {
//     //     //         return child !== visitedNodes[i];
//     //     // })) {
//     //     //     return visitedNodes;
//     //     // }
//     //     // return nodes;
//     // }
// }

export const BREAK = Symbol('break');
export namespace ElementVisit {


    // export const BREAK = BREAK;
    export const visitExit: typeof BREAK = BREAK;
    /**
     * visitor.BREAK 完全停止访问 stop visiting altogether
     * null: 删除这个节点 delete this node
     * undefined: 不进行任何操作
     * array | any value: replace this node with the returned value
     */
    export type VisitElementEnterFnResult<TNode extends SGElement = any> = VisitResult<TNode> | typeof BREAK | null | false | void | undefined;


    type VisitElementLeaveFnResult<TNode> = void;
    /**
     * 访问节点
     */
    export type VisitElementEnterFn<TNode extends SGElement = any> = (node: TNode, parentNode: TNode | undefined, ancestors: ReadonlyArray<TNode>) => VisitElementEnterFnResult<TNode>;
    type VisitElementLeaveFn<TNode extends SGElement = any> = (node: TNode, parentNode: TNode | undefined, ancestors: ReadonlyArray<TNode>) => VisitElementLeaveFnResult<TNode>;

    // {
    //     /**
    //      * Text, Image, Shape
    //      */
    //     [key: ]: Visitor;
    // }
    interface EnterLeaveVisitor {
        readonly enter?: VisitElementEnterFn;
        readonly leave?: VisitElementLeaveFn;
    }

    type KindVisitor = Record<string, VisitElementEnterFn<any> | EnterLeaveVisitor>;

    export type Visitor = EnterLeaveVisitor | KindVisitor | VisitElementEnterFn;




    export function getEnterLeaveForKind(
        visitor: Visitor,
        kind: Kind,
    ): EnterLeaveVisitor {
        if (typeof visitor === 'function') {
            return {
                enter: visitor,
                leave: undefined
            };
        }
        const kindVisitor:
            | VisitElementEnterFn
            | EnterLeaveVisitor
            | undefined = (visitor as KindVisitor)[kind];

        if (typeof kindVisitor === 'object') {
            // { Kind: { enter() {}, leave() {} } }
            return kindVisitor;
        } else if (typeof kindVisitor === 'function') {
            // { Kind() {} }
            return {
                enter: kindVisitor,
                leave: undefined
            };
        }

        return visitor as EnterLeaveVisitor;
    }
    interface ElementNodeVisitor extends NodeVisitor {
        exit: boolean;
    }

    type CollectFn = (element: ViewSGElement, children: SGElement[]) => ViewSGElement;
    function defaultCollect(element: ViewSGElement, children: SGElement[]): ViewSGElement {
        return {
            ...element as any,
            children: children,
        }
    }
    export function nodeVisitor(visitor: Visitor, collectFn: CollectFn = defaultCollect): ElementNodeVisitor {
        const enterLeaveMap = new Map<Kind, EnterLeaveVisitor>();

        for (const kind of Object.values(Kind)) {
            enterLeaveMap.set(kind, getEnterLeaveForKind(visitor, kind));
        }
        let parent: SGElement | undefined = undefined;
        const ancestors: SGElement[] = [];
        const visitEachChildFunction: VisitEachChildFunction<SGElement> = function(node: SGElement, visitor, nodesVisitor, nodeVisitor): SGElement {
            if (parent) {
                ancestors.push(parent)
            }
            parent = node
            if (isGroupElement(node)) {
                const children: SGElement[] = node.children;
                const visitedChildren = nodesVisitor(children, visitor);
                if (visitedChildren !== children) {
                    return collectFn(node, visitedChildren);
                }
            }
            parent = ancestors.pop();
            return node;
        }

        let exit = false;

        const visit: ElementNodeVisitor = function(node: SGElement): SGElement| SGElement[] | undefined {
            if (exit) {
                return node;
            }


            const kind: Kind = node.as as Kind;
            const entry = enterLeaveMap.get(kind)!;
            if (!entry) {
                throw new Error(`visit kind ${kind} not support`)
            }
            const {
                enter,
                leave
            } = entry;
            const nodeParent = parent;
            let visitedNode: VisitElementEnterFnResult<SGElement> = enter?.(node, nodeParent, ancestors);
            if (visitedNode === BREAK) {
                exit = true;
                return node;
            }
            if (visitedNode === false) {
                return node;
            }
            if (visitedNode === undefined) {
                visitedNode = node;
            }
            if (visitedNode == null) {
                return undefined;
            }
            if (node !== visitedNode) {
                return visitedNode;
            }
            visitedNode = visitEachChild(visitedNode, visit, visitEachChildFunction)!;
            if (leave) {
                leave(visitedNode!, nodeParent, ancestors);
            }

            return visitedNode;
        } as ElementNodeVisitor;
        Object.defineProperty(visit, 'exit', {
            get(): any {
                return exit;
            }
        })
        return visit
    }
    // const eachChildFunction: VisitEachChildFunction<SGElementWithChildren>  = function(node: SGElementWithChildren, visitor, nodesVisitor, nodeVisitor): SGElementWithChildren {
    //     let children = node.children;
    //     if (children) {
    //         const newChildren = nodesVisitor(children);
    //         if (newChildren !== children) {
    //             return {
    //                 ...node,
    //                 children: newChildren,
    //             }
    //         }
    //     }
    //     return node;
    // }
    export function visitElement(node: SGElement, visitor: Visitor): SGElement | undefined {
        return visitNode<SGElement>(node, nodeVisitor(visitor));
    }
    export function visitElements(nodes: Array<SGElement>, visitor: Visitor, collectFn: CollectFn = defaultCollect): SGElement[] {
        return visitNodes<SGElement>(nodes, nodeVisitor(visitor, collectFn));
    }
}

/**
 * @obfuscator Document
 */
export interface LayoutLikeType {
    width?: number;
    height?: number;
    background?: BackgroundProperties | null;
    elements: SGElement[];
}
export type DocumentVisitSourceType = Document | Layout | LayoutLikeType;
export namespace DocumentVisit {

    enum Kind {
        Document = 'Document',
        Layout = 'Layout',
        Background = 'Background',
        Element = 'Element',
        Elements = 'Elements',
    }
    type NodeVisitor<N = any, Ou = N | undefined> = (node: N) => Ou | void;

    export type LayoutVisitor = NodeVisitor<PageLayout, PageLayout | false>;
    export type BackgroundVisitor = NodeVisitor<BackgroundProperties>;
    export interface DocumentVisitor {
        layout?: LayoutVisitor
        background?: BackgroundVisitor;
        element?: ElementVisit.Visitor;
    }
    type ElementTestFn = (element: SGElement) => boolean;
    export type ElementPredicate = string | ElementTestFn;
    function predicate(predicate: ElementPredicate): ElementTestFn {
        if (typeof predicate === 'string') {
            const id = predicate;
            return (node) => {
                return node.identifier === id;
            }
        }
        return predicate;
    }

    export function pickElements(
        source: DocumentVisitSourceType,
        test: ElementTestFn
    ): SGElement[] {
        let picked: SGElement[] = [];
        visit(source, {
            element: function(element) {
                //选择之后会跳过子树
                if (test(element)) {
                    picked.push(element)
                    return false;
                }
            }
        })
        return picked;
    }
    export function findElement(
        source: DocumentVisitSourceType,
        id: ElementPredicate
    ): SGElement | undefined {
        const testFn = predicate(id);
        let value: SGElement | undefined;
        visit(source, {
            element: function(element) {
                if (testFn(element)) {
                    value = element;
                    return BREAK;
                }
            }
        })
        return value;

    }
    export function visit<T extends DocumentVisitSourceType>(
        source: T,
        visitor: DocumentVisitor
    ): T {
        let exit = false;


        function nodeVisitor(kind: Kind, value: SGElement | PageLayout | LayoutLikeType | BackgroundProperties | SGElement[]): any {
            if (value === null || value === undefined) {
                return undefined;
            }
            if (exit) {
                return value;
            }
            function visitLayoutEachChild<T extends LayoutLikeType>(layout: T): T {
                let {
                    background,
                    elements,
                } = layout;
                background = background ? nodeVisitor(Kind.Background, background) : background;
                elements = nodeVisitor(Kind.Elements, elements);
                if (
                    layout.background !== background ||
                    layout.elements !== elements
                ) {
                    return {
                        ...layout,
                        background,
                        elements
                    }
                }
                return layout;
            }
            switch (kind) {
                case 'Element': {
                    if (visitor.element) {
                        const elementVisitor = ElementVisit.nodeVisitor(visitor.element)
                        const visited = visitNode<SGElement>(value as SGElement, elementVisitor);
                        if (elementVisitor.exit) {
                            exit = true;
                        }
                        return visited;
                    }
                    break;
                }
                case 'Elements': {
                    if (visitor.element) {
                        const elementVisitor = ElementVisit.nodeVisitor(visitor.element)
                        const visited = visitNodes<SGElement>(value as SGElement[], elementVisitor);
                        if (elementVisitor.exit) {
                            exit = true;
                        }
                        return visited;
                    }
                    break;
                }
                case 'Background': {
                    if (visitor.background) {
                         const background = visitor.background(value as BackgroundProperties);
                         if (background === undefined) {
                             return value;
                         }
                         return background;
                    }
                    break;
                }
                case 'Document': {
                    return visitLayoutEachChild(value as LayoutLikeType);
                }
                case 'Layout': {
                    let layout = value as PageLayout;
                    if (visitor.layout) {
                        let visited = visitor.layout(layout);
                        if (visited === false) {
                            return value;
                        }
                        if (visited === undefined) {
                            visited = layout;
                        }
                        if (visited !== layout) {
                            return visited;
                        }
                    }
                    return visitLayoutEachChild(layout);
                }
            }

            return value;
        }

        // function visitElement(element: SGElementWithChildren) {
        //     if (visitor.element && element) {
        //         return ElementVisit.visitElement(element, visitor.element);
        //     }
        //     return element;
        // }
        // function visitElements(elements: Array<SGElementWithChildren>) {
        //     if (visitor.element && elements) {
        //         const elementVisitor = ElementVisit.nodeVisitor(visitor.element)
        //         const visited = visitNodes<SGElement>(elements, elementVisitor);
        //         if (elementVisitor.exit) {
        //             exit = true;
        //         }
        //         return visited;
        //     }
        //     return elements;
        // }
        // function visitBackground(background: BackgroundProperties | undefined | null): BackgroundProperties | undefined {
        //     if (visitor.background && background) {
        //         const visited = visitor.background(background);
        //         return visited??undefined;
        //     }
        //     return background??undefined;
        // }
        // function visitLayout<L extends LayoutLikeType>(layout: L): L {
        //     let {
        //         width,
        //         height,
        //         background,
        //         elements
        //     } = layout;
        //     if (layout.background) {
        //         background = visitBackground(layout.background);
        //     }
        //     if (layout.elements) {
        //         elements = visitElements(layout.elements);
        //     }
        //     if (
        //         layout.width !== width ||
        //         layout.height !== height ||
        //         layout.background !== background ||
        //         layout.elements !== elements
        //     ) {
        //         return {
        //             ...layout,
        //             background,
        //             elements
        //         }
        //     }
        //     return layout;
        // }
        if (isDocument(source)) {
            if (isPageDocument(source)) {
                if (isPageLayoutDocument(source)) {
                    return nodeVisitor(Kind.Document, source) as T;
                } else {
                    let {
                        background,
                        layouts
                    } = source;
                    // background = background ? nodeVisitor(Kind.Background, background) : background;
                    layouts = visitNodes(layouts, (layout: PageLayout) => {
                        return nodeVisitor(Kind.Layout, layout)
                    })
                    if (
                        background !== source.background ||
                        layouts !== source.layouts
                    ) {
                        return {
                            ...source,
                            background,
                            layouts,
                        }
                    }
                }
            } else if (isImageDocument(source)) {
                return nodeVisitor(Kind.Document, source);
            } else if (isElementDocument(source)) {
                let {
                    element
                } = source;
                element = nodeVisitor(Kind.Element, element)!
                if (element !== source.element) {
                    return {
                        ...source,
                        element
                    }
                }
            } else if (isMaternalDocument(source)) {
                let {
                    elements
                } = source;
                elements = nodeVisitor(Kind.Document, elements)
                if (elements !== source.elements) {
                    return {
                        ...source,
                        elements
                    }
                }
            }
        } else {
            return nodeVisitor(Kind.Document, source) as T;
        }
        return source;
    }
}