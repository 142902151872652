import {Resources} from "../resource";
import {createContext} from "react";


export interface StageContextType {
    resources: Resources;
    rootFontFamilies: string[];
}


export const StageContext = createContext<StageContextType>(null as any)