import {isPageTurnDoctype, isPageConcatDoctype} from "@firefly/fly-design-core";

export function doc2designer(doctype: string) {
    let type = doctype.toLowerCase();
    if (isPageTurnDoctype(doctype)) {
        type = 'invitation-page';
    }
    if (isPageConcatDoctype(doctype)) {
        type = 'page';
    }
    return type;
}