import {MapResourceLoader, Resources} from "../resource";
import {DocumentVisitSourceType, DocumentVisit} from "./visit/visitor";
import {
    StaticMapSGElement
} from "./document";
import {path} from "../util/path";
import {Documents} from "./visit/document";


export namespace Setup {

    /**
     * TODO 应该优化 资源 如何统一接受
     * - 1. 处理后端resource加载
     * - 2. 处理前端resource-asset判断提取确保所有asset加载完成
     *
     *
     * TODO 使用react ConcurrentRoot 可以不用在预加载了 不要使用Suspense拦截 不然失效
     * @param resources
     * @param source
     */
    export function setupResource(resources: Resources, source: DocumentVisitSourceType) {

        function loadImage(uri: string) {
            if (uri) {
                const extname = path.extname(uri)
                let format: string;
                switch (extname) {
                    case ".gif": format = "gif"; break;
                    case ".svg": format = "svg"; break;
                    default: format = "image"; break;
                }
                resources.loadResource({
                    uri: uri,
                    format: format,
                });
            }
        }
        function loadSVG(uri: string, params: any) {
            if (uri) {
                resources.loadResource({
                    uri: uri,
                    format: 'svg',
                    params: params
                });
            }
        }

        Documents.visitSource(source, {
            //TODO 暂时忽略 进行测试fallback
            // background: loadImage,
            element: (source, element) => {
                const {
                    as
                } = element;
                if (as === 'Graphic') {
                    const {
                        properties: {
                            url,
                            palette,
                        }
                    } = element;
                    loadSVG(url, palette);
                } else {
                    loadImage(source);
                }
            }
        })
        DocumentVisit.visit(source, {
            element: {
                StaticMap: (element: StaticMapSGElement) => {
                    const {
                        properties: {
                            location,
                            width,
                            height,
                            zoom,
                        }
                    } = element;
                    resources.loadResource(MapResourceLoader.resolve({
                        location,
                        size: {
                            width,
                            height
                        },
                        zoom,
                    }))
                }
            },
        })

    }
}