


export namespace values {


    export function isTrue(value: string | boolean | undefined | null): boolean {
        if (typeof value === 'string') {
            return value?.toLowerCase() === 'true';
        }
        return !!value;
    }
}