
interface PathSystem {
    toPosix: (path: string) => string;
    basename: (path: string, ext?: string) => string;
    extname: (path: string) => string;

    isExtension(url: string, extension: string | string[]): boolean;
}

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}
function removeUrlParams(url: string): string {
    const re = url.split('?')[0]!;
    return re.split('#')[0]!;
}
function assertPath(path: string)
{
    if (typeof path !== 'string')
    {
        throw new TypeError(`Path must be a string. Received ${JSON.stringify(path)}`);
    }
}
export const path: PathSystem  = {
    toPosix(path: string) { return replaceAll(path, '\\', '/'); },

    /**
     * Returns the last portion of a path
     * @param path - The path to test
     * @param ext - Optional extension to remove
     */
    basename(path: string, ext?: string) {
        assertPath(path);
        if (ext) assertPath(ext);

        path = removeUrlParams(this.toPosix(path));

        let start = 0;
        let end = -1;
        let matchedSlash = true;
        let i: number;

        if (ext !== undefined && ext.length > 0 && ext.length <= path.length) {
            if (ext.length === path.length && ext === path) return '';
            let extIdx = ext.length - 1;
            let firstNonSlashEnd = -1;

            for (i = path.length - 1; i >= 0; --i)
            {
                const code = path.charCodeAt(i);

                if (code === 47)
                {
                    // If we reached a path separator that was not part of a set of path
                    // separators at the end of the string, stop now
                    if (!matchedSlash)
                    {
                        start = i + 1;
                        break;
                    }
                }
                else
                {
                    if (firstNonSlashEnd === -1)
                    {
                        // We saw the first non-path separator, remember this index in case
                        // we need it if the extension ends up not matching
                        matchedSlash = false;
                        firstNonSlashEnd = i + 1;
                    }
                    if (extIdx >= 0)
                    {
                        // Try to match the explicit extension
                        if (code === ext.charCodeAt(extIdx))
                        {
                            if (--extIdx === -1)
                            {
                                // We matched the extension, so mark this as the end of our path
                                // component
                                end = i;
                            }
                        }
                        else
                        {
                            // Extension does not match, so our result is the entire path
                            // component
                            extIdx = -1;
                            end = firstNonSlashEnd;
                        }
                    }
                }
            }

            if (start === end) end = firstNonSlashEnd; else if (end === -1) end = path.length;

            return path.slice(start, end);
        }
        for (i = path.length - 1; i >= 0; --i) {
            if (path.charCodeAt(i) === 47)
            {
                // If we reached a path separator that was not part of a set of path
                // separators at the end of the string, stop now
                if (!matchedSlash)
                {
                    start = i + 1;
                    break;
                }
            }
            else if (end === -1)
            {
                // We saw the first non-path separator, mark this as the end of our
                // path component
                matchedSlash = false;
                end = i + 1;
            }
        }

        if (end === -1) return '';

        return path.slice(start, end);
    },
    /**
     * Returns the extension of the path, from the last occurrence of the . (period) character to end of string in the last
     * portion of the path. If there is no . in the last portion of the path, or if there are no . characters other than
     * the first character of the basename of path, an empty string is returned.
     * @param path - The path to parse
     */
    extname(path: string) {
        assertPath(path);
        path = removeUrlParams(this.toPosix(path));

        let startDot = -1;
        let startPart = 0;
        let end = -1;
        let matchedSlash = true;
        // Track the state of characters (if any) we see before our first dot and
        // after any path separator we find
        let preDotState = 0;

        for (let i = path.length - 1; i >= 0; --i)
        {
            const code = path.charCodeAt(i);

            if (code === 47)
            {
                // If we reached a path separator that was not part of a set of path
                // separators at the end of the string, stop now
                if (!matchedSlash)
                {
                    startPart = i + 1;
                    break;
                }
                continue;
            }
            if (end === -1)
            {
                // We saw the first non-path separator, mark this as the end of our
                // extension
                matchedSlash = false;
                end = i + 1;
            }
            if (code === 46)
            {
                // If this is our first dot, mark it as the start of our extension
                if (startDot === -1) startDot = i;
                else if (preDotState !== 1) preDotState = 1;
            }
            else if (startDot !== -1)
            {
                // We saw a non-dot and non-path separator before our dot, so we should
                // have a good chance at having a non-empty extension
                preDotState = -1;
            }
        }

        if (
            startDot === -1 || end === -1
            // We saw a non-dot character immediately before the dot
            || preDotState === 0
            // The (right-most) trimmed path component is exactly '..'
            // eslint-disable-next-line no-mixed-operators
            || preDotState === 1 && startDot === end - 1 && startDot === startPart + 1
        )
        {
            return '';
        }

        return path.slice(startDot, end);
    },

    /**
     *
     * @param url
     * @param extension  .png .jpeg .jpg .svg
     */
    isExtension(url: string, extension: string | string[]) {
        const tempURL = url.split('?')[0];
        const ext = path.extname(tempURL).toLowerCase();

        if (Array.isArray(extension)) {
            return extension.includes(ext);
        }
        return ext === extension;
    }
};