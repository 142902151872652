import {cloneDeep as clone, mapValues} from "lodash-es";
import {
    Document,
    SGElement,
    Documents,
    FillStyleOptions,
    BackgroundProperties,
    Visitor,
    GridGraphicElement,
    StaticMapElement, GraphicElement, TextElement, ImageElement
} from "@firefly/fly-design-core";
import {GraphicPathType, Color, SGProps, isColorFillStyleOptions} from "@firefly/fly-design-core";
import {DocumentVisit} from "@firefly/fly-design-core";
import DocumentVisitor = DocumentVisit.DocumentVisitor;



export function isColor(color: any): color is Color {
    return (
        typeof color === "string" &&
        (color.length === 6 ||
            color.length === 8 ||
            (color.startsWith("#") && (color.length === 4 ||  color.length === 7 || color.length === 9)))
    );
}
export function color2hex(color: Color | undefined): string | undefined {
    if (!isColor(color)) {
        return undefined;
    }
    if (color?.startsWith("#")) {
        if (color.length === 4) {
            const h1 = color.substring(1, 2);
            const h2 = color.substring(2, 3);
            const h3 = color.substring(3, 4);
            return `#${h1}${h1}${h2}${h2}${h3}${h3}`;
        }
        /**
         * 对颜色进行升级
         */
        function color2hex(color: Color): string {
            const h1 = color.substring(0, 2);
            const h2 = color.substring(2, 4);
            const h3 = color.substring(4, 6);
            if (color.length === 6) {
                return `#${h1}${h2}${h3}`;
            } else {
                const h4 = color.substring(6, 8);
                return `#${h2}${h3}${h4}${h1}`;
                // return `rgba(${h2}, ${h3}, ${h4}, ${h1 / 255})`;
            }
        }
        return color2hex(color.substring(1));
    } else {
        throw new Error(`unknown color format: ${color} `)
    }
}
export default function upgrade<T extends Document = Document>(input: T): T {
    const upgradeElementVisitor: DocumentVisitor['element'] = function(element: SGElement) {
        const { as } = element;
        const properties: SGProps = element.properties;
        delete properties["identifier"];
        delete properties["rotate"];
        delete element["attributes"];
        switch (as) {
            case "Image": {
                const props: ImageElement = properties as ImageElement;
                const {
                    content
                } = props;
                delete content['scale'];
                break
            }
            case "Text": {
                const props: TextElement = properties as TextElement;
                const {
                    style
                } = props;
                style.color = color2hex(style.color);
                delete style["writingMode"]
                break;
            }
            case "Graphic": {
                const props: GraphicElement = properties as GraphicElement;
                const {
                    palette
                } = props;
                props.palette = mapValues(palette, (v) => {
                    return color2hex(v)!;
                })
                break;
            }
            case "StaticMap": {
                const props : StaticMapElement = properties as StaticMapElement;
                const {
                    location
                } = props;
                props.location = {
                    lng: location.lng,
                    lat: location.lat
                }
                break;
            }
            case "GridGraphic": {
                const props : GridGraphicElement = properties as GridGraphicElement;
                const {
                    paths
                } = props;

                paths.forEach(function(path: GraphicPathType) {
                    let pathFill = path.fill;
                    let fill: FillStyleOptions | undefined = undefined;
                    if (pathFill) {
                        if (typeof pathFill === 'string') {
                            fill = {
                                type: "color",
                                color: color2hex(pathFill)!
                            };
                        } else if (isColorFillStyleOptions(pathFill)) {
                            fill = {
                                ...pathFill,
                                color: color2hex(pathFill.color)!
                            };
                        } else {
                            fill = pathFill;
                        }
                    }
                    path.fill = fill;
                    // let stroke = path.stroke;
                    // if (stroke) {
                    //     // const stroke = path.stroke;
                    //     if (isColorFillStyleOptions(stroke)) {
                    //         stroke = {
                    //             ...stroke,
                    //             color: color2hex(stroke.color)!
                    //         };
                    //     }
                    // }
                })
            }
        }
        return element;
    };

    let document: Document = clone(input);
    delete document["fonts"]
    delete document["theme"]
    document = DocumentVisit.visit(document, {
        background: function(background: BackgroundProperties | undefined): BackgroundProperties | undefined {
            if (background) {
                background.color = color2hex(background.color);
            }
            return background;
        },
        element: upgradeElementVisitor,
    })
    // Documents.visit(document, {
    //     background: (background) => {
    //         if (background) {
    //             background.color = color2hex(background.color);
    //         }
    //         return background;
    //     },
    //     element: upgradeElementVisitor,
    // })
    document.version = "1.2";
    return document as T;
}