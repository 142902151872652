import {
    ViewSGElement,
    ImageSGElement,
    SGElement,
    ShapeSGElement,
    StaticMapSGElement,
    TextSGElement,
    VideoSGElement
} from "../document";
import {BREAK, ElementVisit} from "./visitor";
import {Kind} from "../../typing";



export type NodePredicate = (element: SGElement) => boolean;
export type ElementPredicateType = string | NodePredicate;
export type ElementAttributesType = Record<string, any>;
export namespace Elements {
    const visitExit: typeof BREAK = BREAK;
    const visitSkip = false;

    export function predicate(predicate: ElementPredicateType): NodePredicate {
        if (typeof predicate === 'string') {
            const id = predicate;
            return (node) => {
                return node.identifier === id;
            }
        }
        return predicate;
    }
    export function route(elements: SGElement[], predicate: ElementPredicateType): SGElement[] | null {
        if (!predicate) {
            throw new Error("predicate required");
        }
        const nodePredicate = Elements.predicate(predicate);
        // console.log(`route`, predicate,nodePredicate)

        let route: SGElement[] | null = null;
        ElementVisit.visitElements(elements, {
            enter: function (node)  {
                if (route) {
                    return visitExit;
                }
                if (nodePredicate(node)) {
                    route = [node];
                    return visitExit;
                }

            },
            leave: function(node) {
                if (route) {
                    route.push(node);
                }
            }
        })
        return route;
    }
    export function findParents(elements: SGElement[], predicate: ElementPredicateType): SGElement[] | null {
        const nodePredicate = Elements.predicate(predicate);
        const parents: SGElement[] = [];
        let findNode: SGElement | null = null;
        ElementVisit.visitElements(elements, {
            enter: function(node: SGElement)  {
                if (findNode) {
                    return visitExit;
                }
                if (nodePredicate(node)) {
                    findNode = node;
                    return visitExit;
                }
                return
            },
            leave: (node) => {
                if (findNode) {
                    parents.push(node);
                }
            }
        })
        return findNode ? parents : null;
    }

    export function findNodes(elements: SGElement[], predicate: ElementPredicateType): SGElement[] | null {
        const nodePredicate = Elements.predicate(predicate);
        const nodes: SGElement[] = [];
        let findNode: SGElement | null = null;
        ElementVisit.visitElements(elements, {
            enter: (node: SGElement) => {
                if (findNode) {
                    return visitExit;
                }
                if (nodePredicate(node)) {
                    findNode = node;
                    return visitExit;
                }
                return;
            },
            leave: (node) => {
                if (findNode) {
                    nodes.push(node);
                }
            }
        })
        return findNode ? nodes : null;
    }

    export function find(elements: SGElement[], predicate: ElementPredicateType): SGElement | null
    export function find<T extends SGElement = SGElement>(elements: SGElement[], predicate: ElementPredicateType): T | null
    export function find<T extends SGElement = SGElement>(elements: SGElement[], predicate: ElementPredicateType): T | null {

        const nodePredicate = Elements.predicate(predicate);
        let findNode: T | null = null;
        ElementVisit.visitElements(elements, {
            enter: (node: SGElement) => {
                if (findNode) {
                    return visitExit;
                }
                if (nodePredicate(node)) {
                    findNode = node as T;
                    return visitExit;
                }
                return
            }
        })
        return findNode;
    }

    export function findParent(elements: SGElement[], predicate: ElementPredicateType): SGElement | undefined | symbol {
        let nodePredicate = Elements.predicate(predicate);
        let parent: SGElement | symbol | undefined = visitExit;
        ElementVisit.visitElements(elements, {
            enter: (node: SGElement, parentNode) => {
                if (nodePredicate(node)) {
                    parent = parentNode;
                    return visitExit
                }
                return
            }
        })
        return parent;
    }

    /**
     * 不是遍历每个元素 选择之后会跳过子树
     */
    export function pick(
        elements: SGElement[],
        predicate: (element: SGElement) => boolean
    ): SGElement[] {
        const picked: SGElement[] = [];
        ElementVisit.visitElements(elements, {
            enter: (element: SGElement) => {
                if (predicate(element)) {
                    picked.push(element);
                    return visitSkip;
                }
                return
            },
        });
        return picked;
    }


}
