import { ElementEffectAnimation } from "@firefly/fly-design-core";

function upgradeMotion(
  type: string,
  name: string,
  args: Record<string, string>
): {
  name: string;
  args: Record<string, any>;
} | null {
  if (type === "enter") {
    return upgradeEnterMotion(name, args);
  }
  if (type === "spring") {
    return upgradeSpringMotion(name, args);
  }
  if (type === "leave") {
    return upgradeExitMotion(name, args);
  }
  return null;
}

function upgradeEnterMotion(
  name: string,
  args: Record<string, string>
): {
  name: string;
  args: Record<string, any>;
} | null {
  const place = {
    left: "right",
    right: "left",
    up: "down",
    down: "up",
  }[args.direction];
  switch (name) {
    //渐变
    case "fadeIn":
    case "fade": {
      return {
        name: "fadeIn",
        args: {},
      };
    }
    case "bounceIn":
    case "bounce": {
      return {
        name: "zoomElasticIn",
        args: {},
      };
    }
    //滑入，弹性滑入
    case "slideIn":
    case "slide": {
      const { bounces, direction } = args;

      return {
        name: bounces ? "slideElasticIn" : "slideIn",
        args: {
          place: place,
        },
      };
    }
    //砸落
    case "dropIn":
    case "drop": {
      return {
        name: "dropIn",
        args: {},
      };
    }
    //缩放

    case "zoomEnter":
    case "zoomIn":
    case "zoom": {
      const { style, intensity } = args;

      if (style === "in") {
        return {
          name: "zoomIn",
          args: {},
        };
      } else {
        return {
          name: "zoomIn",
          args: {},
        };
      }
    }
    //滚入
    case "roll": {
      const { direction } = args;
      return {
        name: "rollIn",
        args: {
          place: place,
        },
      };
    }
    //翻入
    case "flipIn":
    case "flip": {
      const { direction } = args;
      const axis = {
        left: "x",
        right: "x",
        up: "y",
        down: "y",
      }[direction];
      return {
        name: "flipIn",
        args: {
          axis: axis ?? "x",
        },
      };
    }
    case "rotate":
    case "rotateIn":
    case "rotateByOrigin": {
      const { origin } = args;
      return {
        name: "rotateIn",
        args: {
          origin: origin ?? "c",
        },
      };
    }
    default: {
      console.warn(`animation: ${name} upgrade failure, is mismatch motion`);
    }
  }
  return null;
}
function upgradeSpringMotion(
  name: string,
  args: Record<string, string>
): {
  name: string;
  args: Record<string, any>;
} | null {
  switch (name) {
    //闪烁
    case "twinkle": {
      return {
        name: "twinkle",
        args: {},
      };
    }
    //摇摆
    case "swing": {
      return {
        name: "swing",
        args: {},
      };
    }
    //晃动
    case "wobble": {
      return {
        name: "wobble",
        args: {},
      };
    }
    //抖动
    case "shake": {
      return {
        name: "shake",
        args: {},
      };
    }
    //旋转
    case "rotate360": {
      return {
        name: "rotate360",
        args: {},
      };
    }
    //缩放使用心跳动画代替
    case "zoom": {
      return {
        name: "heartbeat",
        args: {},
      };
    }
    //心跳
    case "beat": {
      return {
        name: "heartbeat",
        args: {},
      };
    }
    default: {
      console.warn(`animation: ${name} upgrade failure, is mismatch motion`);
    }
  }
  return null;
}

function upgradeExitMotion(
  name: string,
  args: Record<string, string>
): {
  name: string;
  args: Record<string, any>;
} | null {
  const place = {
    left: "left",
    right: "right",
    up: "up",
    down: "down",
  }[args.direction];
  switch (name) {
    //渐变
    case "fade": {
      return {
        name: "fadeOut",
        args: {},
      };
    }
    // case 'bounce': {
    //   return {
    //     name: 'zoomElasticIn',
    //     args: {
    //
    //     }
    //   }
    // }

    // //砸落
    // case 'drop': {
    //   return {
    //     name: 'dropIn',
    //     args: {
    //
    //     }
    //   }
    // }
    //缩放
    case "zoomLeave":
    case "zoom": {
      const { style, intensity } = args;

      return {
        name: "zoomOut",
        args: {},
      };
    }
    //滑入，弹性滑入
    case "slideOut":
    case "slide": {
      const { bounces, direction } = args;

      return {
        name: bounces ? "slideElasticOut" : "slideOut",
        args: {
          place: place,
        },
      };
    }
    //翻出
    case "flipOut":
    case "flip": {
      const { direction } = args;
      const axis = {
        left: "x",
        right: "x",
        up: "y",
        down: "y",
      }[direction];
      return {
        name: "flipOut",
        args: {
          axis: axis ?? "x",
        },
      };
    }

    //滚入
    case "roll": {
      const { direction } = args;
      return {
        name: "rollOut",
        args: {
          place: place,
        },
      };
    }
    default: {
      console.warn(`animation: ${name} upgrade failure, is mismatch motion`);
    }
  }
  return null;
}

export function upgradeAnimation(input: any): ElementEffectAnimation | null {
  //若有when代表是新版的
  if (input.when) {
    return input;
  }
  const { type, name, args, duration, delay, iterations } = input;

  const motion = upgradeMotion(type, name, args);
  if (!motion) {
    console.warn(`animation: ${type}-${name} upgrade failure`);
    return null;
  }
  const when: string = (
    {
      enter: "enter",
      spring: "spring",
      leave: "exit",
    } as Record<string, string>
  )[type];
  return {
    ...motion,
    when: when as any,
    duration,
    delay,
    iterations,
  };
}
