import useConfigQuery from "../context/webapp/useConfigQuery";
import {WebappConfig} from "../context/webapp/WebappConfigContext";
// import {openClientService} from "../util/help";
import {Toast} from "antd-mobile";


function useCustomerService() {
    const {
        value,
        isPending
    } = useConfigQuery<WebappConfig['cs']>("cs");

    function navigate() {
        const uri = new URL(value!);
        switch (uri.protocol) {
            case "http:":
            case "https:": {
                window.location.href = uri.toString();
                return;
            }
            case "qiyukf:": {
                // openClientService(uri.pathname);
                return;
            }
        }
        Toast.show({
            content: "尚未配置客服"
        })
    }
    return {
        isPending,
        navigate
    }
}

export default useCustomerService;