import {Transform} from "../typing/transform";
import {
    BackgroundProperties,
    TextElement,
    ImageElement,
    VideoElement,
    GroupElement,
    ClippathImageElement,
    ShapeElement,
    GraphicElement,
    ClippathVideoElement,
    GridGraphicElement,
    StaticMapElement,
    BitmapTextElement, Kind
} from "../typing";
import {Music} from "./music";
// import {ElementWithChildren, Music, PageCustomization, PageTheme, Ruler} from "../doc/Document";

/**
 * @obfuscator Element
 */
export interface HasChildren<T> {
    children: WithChildren<T>[];
}
export type WithChildren<E> = E & HasChildren<E>;

export type Ruler = "px" | "cm" | "mm";
export type Doctype =
    | "element"
    | "background"
    | "image"
    | "page/turn"
    | "page/concat"
    | "page/layout"
    | "material";

/**
 * stage graphic
 * @obfuscator Element
 */
export interface SGProps {
    x: number;
    y: number;
    width: number;
    height: number;
    name?: string
    transform?: Transform;
    opacity?: number;
    hidden?: boolean;
}

/**
 * @obfuscator Element
 */
export interface _SGElement<TKind extends Kind, P extends SGProps = any> {
    identifier: string;
    as: TKind;
    title?: string;
    editable?: Record<string, any>;
    properties: P;
    metadata?: Record<string, any>;
}


export type TextSGElement = _SGElement<Kind.Text, TextElement>;
export type ImageSGElement = _SGElement<Kind.Image, ImageElement>;
export type VideoSGElement = _SGElement<Kind.Video, VideoElement>;
export type ShapeSGElement = _SGElement<Kind.Shape, ShapeElement>;
export type StaticMapSGElement = _SGElement<Kind.StaticMap, StaticMapElement>;
export type ClippathImageSGElement = _SGElement<Kind.ClippathImage, ClippathImageElement>;
export type GraphicSGElement = _SGElement<Kind.Graphic, GraphicElement>;
export type ClippathVideoSGElement = _SGElement<Kind.ClippathImage, ClippathVideoElement>;
export type GridGraphicSGElement = _SGElement<Kind.GridGraphic, GridGraphicElement>;
export type BitmapTextSGElement = _SGElement<Kind.BitmapText, BitmapTextElement>;

type BasicSGElement = TextSGElement | ImageSGElement | VideoSGElement | ShapeSGElement | StaticMapSGElement | ClippathVideoSGElement | GridGraphicSGElement | GraphicSGElement | BitmapTextSGElement;

export type ViewSGElement = _SGElement<Kind.View, Omit<GroupElement, 'children'>> & HasChildren<BasicSGElement | ViewSGElement>;

/**
 * @obfuscator Element
 */
export type SGElement = BasicSGElement | ViewSGElement;

/**
 * @obfuscator Document
 */
interface BasicDocument {
    schema: "SIG";
    //1.0
    version: string;
    type: Doctype;
}




/**
 * @obfuscator Document
 */
export interface ImageDocument extends BasicDocument {
    type: "image";
    width: number;
    height: number;
    ruler: Ruler;
    background?: BackgroundProperties;
    elements: SGElement[];
}
/**
 * @obfuscator Document
 */
export interface ElementDocument extends BasicDocument {
    type: "element";
    element: SGElement;
}
/**
 * @obfuscator Document
 */
export interface BackgroundDocument extends BasicDocument {
    type: "background";
    background?: BackgroundProperties | null;
}
/**
 * @obfuscator Document
 */
export interface ImageDocument extends BasicDocument {
    type: "image";
    width: number;
    height: number;
    ruler: Ruler;
    background?: BackgroundProperties;
    elements: SGElement[];
}

/**
 * @obfuscator Document
 */
export interface Layout {
    width: number;
    height: number;
    background?: BackgroundProperties;
    elements: SGElement[];
}
/**
 * @obfuscator Document
 */
export interface PageLayout extends Layout {
    identifier: string;
}
/**
 * TODO 废除了？？
 * @obfuscator Document
 */
export interface AutoPlayOptions {
    /**
     * 启用
     */
    enabled: boolean;

    /**
     * 停留时长
     * 毫秒
     */
    stay?: number;

    /**
     * 当进入交互时 停止
     * default
     */
    stopOnInteraction?: boolean;
}

/**
 * @obfuscator Document
 */
export interface PageLayoutDocument extends BasicDocument {
    type: "page/layout";
    width: number;
    height: number;
    background?: BackgroundProperties | null;
    elements: SGElement[];
}

export type PageTheme = "NONE" | "INVITATION" | string;

/**
 * @obfuscator Document
 */
// export type InvitationThemeCustomization = {
//     //前置加载颜色
//     themeColor?: Color;
//     primaryColor?: Color;
// };
// export type PageCustomization =
//     | InvitationThemeCustomization
//     | Record<string, string>;
/**
 * @obfuscator Document
 */
interface BasicPageDocument extends BasicDocument {
    type: "page/concat" | "page/turn";
    width: number;
    height: number;
    backgroundMusic: Music | null;
    background: BackgroundProperties | null | undefined;
    /**
     * @deprecated 好像没有多大意义 准备废除
     */
    theme: PageTheme;
    // customization: PageCustomization;
    //封面
    // envelope: Envelope | null;
    //布局
    layouts: PageLayout[];
    autoplay: AutoPlayOptions;
}

/**
 * @obfuscator Document
 */
export interface PageConcatDocument extends BasicPageDocument {
    type: "page/concat";
}

/**
 * @obfuscator Document
 */
export interface PageTurnDocument extends BasicPageDocument {
    type: "page/turn";
}

export type PageLayoutsDocument = PageConcatDocument | PageTurnDocument;
export type PageDocument = PageLayoutsDocument | PageLayoutDocument;



/**
 * 例如复制粘贴使用
 * @obfuscator Document
 */
export interface MaterialDocument extends BasicDocument {
    type: "material";
    width: number;
    height: number;
    elements: SGElement[];
}

export type Document =
    | ImageDocument
    | ElementDocument
    | PageConcatDocument
    | PageTurnDocument
    | PageLayoutDocument
    | BackgroundDocument
    | MaterialDocument;



export function isImageDoctype(type: string | undefined): boolean {
    return type?.toLowerCase() === "image";
}
export function isElementDoctype(type: string | undefined): boolean {
    return type?.toLowerCase() === "element";
}
export function isPageDoctype(type: string | undefined): boolean {
    return !!type && type.toLowerCase().startsWith("page/");
}
export function isPageTurnDoctype(type: string | undefined): boolean {
    return type?.toLowerCase() === "page/turn";
}
export function isPageConcatDoctype(type: string | undefined): boolean {
    return type?.toLowerCase() === "page/concat";
}
export function isPageLayoutsDoctype(type: string | undefined): boolean {
    return isPageTurnDoctype(type) || isPageConcatDoctype(type);
}
export function isPageLayoutDoctype(type: string | undefined): boolean {
    return type?.toLowerCase() === "page/layout";
}
export function isBackgroundDocType(type: string | undefined): boolean {
    return type?.toLowerCase() === "background";
}
export function isMaterialDocType(type: string | undefined): boolean {
    return type?.toLowerCase() === "material";
}

export function isDocument(value: any): value is Document {
    const v: Document = value;
    return v.type && v.schema === 'SIG';
}
export function isImageDocument(
    value: Document | undefined
): value is ImageDocument {
    return isImageDoctype(value?.type);
}
export function isElementDocument(
    value: Document | undefined
): value is ElementDocument {
    return isElementDoctype(value?.type);
}

export function isPageLayoutDocument(
    value: Document | undefined
): value is PageLayoutDocument {
    return isPageLayoutDoctype(value?.type);
}
export function isPageTurnDocument(
    value: Document | undefined
): value is PageTurnDocument {
    return isPageTurnDoctype(value?.type);
}
export function isPageConcatDocument(
    value: Document | undefined
): value is PageConcatDocument {
    return isPageConcatDoctype(value?.type);
}

export function isPageLayoutsDocument(
    value: Document | undefined
): value is PageLayoutsDocument {
    return isPageLayoutsDoctype(value?.type);
}

export function isPageDocument(
    value: Document | undefined
): value is PageDocument {
    return isPageDoctype(value?.type);
}

export function isBackgroundDocument(
    value: Document | undefined
): value is BackgroundDocument {
    return isBackgroundDocType(value?.type);
}

export function isMaternalDocument(
    value: Document | undefined
): value is MaterialDocument {
    return isMaterialDocType(value?.type);
}



function isElement(element: any, kind: Kind): element is SGElement {
    return element && typeof element === 'object' && element.as === kind;
}
export function isTextElement(element: any): element is TextSGElement {
    return isElement(element, Kind.Text);
}
export function isImageElement(element: any): element is ImageSGElement {
    return isElement(element, Kind.Image);
}
export function isClippathImageElement(element: any): element is ClippathImageSGElement {
    return isElement(element, Kind.ClippathImage)
}
export function isVideoElement(element: any): element is VideoSGElement {
    return isElement(element, Kind.Video);
}
export function isGraphicElement(element: any): element is GraphicSGElement {
    return isElement(element, Kind.Graphic);
}
export function isGridGraphicElement(element: any): element is GridGraphicSGElement {
    return isElement(element, Kind.GridGraphic);
}
export function isShapeElement(element: any): element is ShapeSGElement {
    return isElement(element, Kind.Shape);
}
export function isStaticMapElement(element: any): element is StaticMapSGElement {
    return isElement(element, Kind.StaticMap);
}
export function isGroupElement(element: any): element is ViewSGElement {
    return isElement(element, Kind.View);
}

export function isElementHasChildren(element: any): element is ViewSGElement {
    return isGroupElement(element) && element.children?.length > 0;
}