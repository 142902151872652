

import {report} from "tracekit";
import {http} from "@firefly/fly-http";



report.subscribe(function(stackTrace, isWindowError, error) {
    http.post(`/api/events/report`, {
        payload: {
            action: 'ERROR',
            label: stackTrace.message,
            extra: {
                mode: stackTrace.mode,
                name: stackTrace.name,
                message: stackTrace.message,
                useragent: stackTrace.useragent,
                url:  window.location.href,
                stack:  {
                    url: stackTrace.url,
                    stack: JSON.stringify(stackTrace.stack),
                },
                error: JSON.stringify(error),
            },
        },
    }).then(() => {

    }, (reason) => {
        console.warn("report failure:", reason)
    })
})

export {
    report
}