
import {InvitationDesign} from "@firefly/fly-design-inv-data";
import {NavigationOptions} from "../suppport/router";
import {useNavigateToDesignDesigner} from "./useNavigateToDesignDesigner";
import {isPageDoctype, isImageDoctype} from "@firefly/fly-design-core";
import {doc2designer} from "./util";


function useNavigateToDesignPreview() {
  const navigateToDesigner = useNavigateToDesignDesigner();
  return function (
      design: Pick<InvitationDesign, 'id' | 'doctype'>,
      options: NavigationOptions = {}
  ) {
    const {
      id,
      doctype
    } = design;
    const {
      replace
    } = options;
    let type = doc2designer(doctype);
    if (type === 'invitation-page') {
      type = 'page'
    }
    let uri;
    if (isPageDoctype(doctype)) {
      const location = window.location;
      const target = `/app/invitation/${type}-inspect.html?id=${id}`;
      //兼容，确保同一个域名
      if (replace) {
        location.replace(target)
      } else {
        location.assign(target);
      }
    } else if (isImageDoctype(doctype)){
      // uri = `/page/design/image-designer`
      return navigateToDesigner(design, options);
    } else {
      throw new Error(`doctype[${doctype}] unable navigateToInspect`)
    }

  }
}

export default useNavigateToDesignPreview;
