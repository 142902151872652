import {CSSProperties, useMemo} from "react";
import TemplateCard from "src/component/TemplateCard";
import "./TemplateGrid.scss";
import classNames from "classnames";
import {useNavigateToTemplate} from "src/suppport/router";
import {isPageDoctype} from "@firefly/fly-design-core";
import {GLOBAL} from "../../../config";
import {ViewerWorkTemplate, WorkTemplate} from "@firefly/fly-design-inv-data";

interface TemplateGridProps {
    className?: string;
    style?: CSSProperties;
    gutter?: number;
    rows?: number;
    data?: ViewerWorkTemplate[];
}
function TemplateGrid({
    className,
    style,
    gutter = 6,
    rows: defaultRows,
    data = []
}: TemplateGridProps) {

    const navigate = useNavigateToTemplate();
    const height = useMemo(() => {
        const matchMedia = window.matchMedia('(min-width: 600px)')
        if (matchMedia.matches) {
            return 260 + 40;
        }
        return 178 + 40;
    }, []);


    const rows = useMemo(() => {
        if (defaultRows) {
            return defaultRows;
        }
        return data?.every((item) => {
            return isPageDoctype(item.doctype);
        }) ? 2 : 1;
    }, [defaultRows, data])

    const datasource = useMemo(() => {
        return data.reduce((value, item, index) => {
            value[index % rows].push(item);
            return value;
        }, [[] ,[]] as ViewerWorkTemplate[][])
    }, [data, rows]);

    return <div className={classNames("template-grid", className)} style={style}>
        <div className={"template-grid-body"} style={{
            paddingLeft: gutter,
            paddingRight: gutter,
            gap: gutter
        }}>
            {datasource.map((row, index) => {
                return <div key={index} className={"template-grid-row"} style={{
                    gap: gutter
                }}>
                    {row.map((item) => {
                        return <div key={item.id} className={"template-grid-item"}>
                            <TemplateCard
                                hiddenPayment={GLOBAL.template.paymentHidden}
                                height={height}
                                minFit={0.5}
                                value={item}
                                onClick={() => {
                                    navigate(item)
                                }}
                            />
                        </div>
                    })}
                </div>
            })}
        </div>
    </div>
}


export default TemplateGrid;
