
import {FillStyleOptions, StrokeDasharrayType, StrokeStyleOptions} from "./filling";
import {TextEffect} from "./text-layer";
import {Transform} from "./transform";
import {
    Color,
    Ellipsis, IEdge,
    ImageContentType,
    IRectangle,
    LocationMap,
    TextAligns,
    TextStyle,
    ViewBoxType,
    WritingMode
} from "./basic";
import {SGProps, WithChildren} from "../sig";
import {HasChildren} from "../sig/document";


export enum Kind {
    View = 'View',
    Text = 'Text',
    Image = 'Image',
    ClippathImage = 'ClippathImage',

    Shape = 'Shape',
    // Group = 'Group',
    // Layout = 'Layout',
    // Background = 'Background',
    Graphic = 'Graphic',
    GridGraphic = 'GridGraphic',
    StaticMap = 'StaticMap',
    Video = 'Video',
    BitmapText = 'BitmapText',
}


export type TextAttributes = {
    style: TextStyle;

    ellipsis?: Ellipsis;

    textAlign?: TextAligns;

    writingMode?: WritingMode;
}

/**
 * @obfuscator Element
 */
export interface Element {
    x: number;
    y: number;
    width: number;
    height: number;
    name?: string
    transform?: Transform;
    /**
     * 不透明度
     * @remarks
     * - Minimum: 0
     * - Maximum: 1
     */
    opacity?: number;

    //
    // transparency

    //以后要使用的
    rotation?: number;

    hidden?: boolean;
}


/**
 * @obfuscator Element
 */
export interface TextElement extends Element, TextAttributes {
    text?: string;
    fill?: FillStyleOptions;
    effect?: TextEffect;
}
/**
 * @obfuscator Element
 */
export interface ImageElement extends Element {
    preserveAspectRatio?: boolean;
    content: ImageContentType;
}


/**
 * @obfuscator Element
 */
export interface VideoElement extends Element {
    preserveAspectRatio?: boolean;
    content: ImageContentType;
}

/**
 * @obfuscator Element
 */
export interface ShapeStrokePath {
    stroke: StrokeStyleOptions;
    strokeWidth: number;
    strokeDasharray?: StrokeDasharrayType;
}


/**
 * @obfuscator Element
 */
export interface ShapePath extends Partial<ShapeStrokePath> {
    define: string;
    fill?: FillStyleOptions;
    stroke?: StrokeStyleOptions;
    strokeDasharray?: StrokeDasharrayType;
    strokeWidth?: number;
    round?: number;
}

/**
 * @obfuscator Element
 */
export interface ShapeElement extends Element {
    viewBox: ViewBoxType;
    grid?: IRectangle;
    paths: ShapePath[];
}

/**
 * @obfuscator Element
 */
export interface StaticMapElement extends Element, LocationMap {

}

/**
 * @deprecated 准备删除元素
 * @obfuscator Element
 */
export interface GraphicElement extends Element {
    //SVG URL
    url: string;

    //调色板
    palette?: Record<string, Color>;
    //{ [key: string]: Color };

    preserveAspectRatio?: "none";
}

/**
 * @deprecated 准备删除元素
 * @obfuscator Element
 */
export interface GraphicPathType {
    define: string;
    //TODO 准备废除
    fill?: FillStyleOptions | string;
    stroke?: StrokeStyleOptions;
}


/**
 * @deprecated 准备删除元素
 * @obfuscator Element
 */
export interface GridGraphicElement extends Element {
    viewBox: IRectangle;
    paths: GraphicPathType[];
    grid: IEdge;
    zoom?: number;
}


/**
 * @obfuscator Element
 */
export interface TextBitmap {
    width: number;
    height: number;
    src: string;
}
/**
 * @obfuscator Element
 */
export interface BitmapTextElement extends Element, TextAttributes {
    text?: string;
    effect?: TextEffect;
    bitmap?: TextBitmap;
}

/**
 * @deprecated 准备删除元素
 * @obfuscator Element
 */
export interface ClippathImageElement extends Element {
    //TODO 这个考虑展示到可编辑配置
    preserveAspectRatio?: "none";
    viewBox: IRectangle;
    path: string;
    content: ImageContentType;
}
/**
 * @deprecated 准备删除元素
 * @obfuscator Element
 */
export interface ClippathVideoElement extends Element {
    //TODO 这个考虑展示到可编辑配置
    preserveAspectRatio?: "none";
    viewBox: IRectangle;
    path: string;
    content: ImageContentType;
}
type BasicElements = TextElement | VideoElement | ShapeElement | ImageElement | StaticMapElement | GraphicElement | GridGraphicElement | BitmapTextElement | ClippathImageElement | ClippathVideoElement;


export type GroupElement = Element & HasChildren<BasicElements | GroupElement>;

// export type FixedElement = BasicElements | GroupElement;

// export type FixedElement =
//     TextElement |
//     (Element & WithChildren<Element>)
