



/**
 * undefined: 删除这个节点 delete this node
 * array | any value: replace this node with the returned value
 */
export type VisitResult<T> = T | T[] | undefined;
export type Visitor<TIn = any, TOut = TIn> = (node: TIn) => VisitResult<TOut>;
export interface NodesVisitor {
    <N>(nodes: Array<N>, visitor: Visitor<N>, start?: number, count?: number): Array<N>;
}
export interface NodeVisitor {
    <N>(nodes: N, visitor: Visitor<N>): N | undefined;
}
export type VisitEachChildFunction<N> = (node: N, visitor: Visitor<N>, nodesVisitor: NodesVisitor, nodeVisitor: NodeVisitor) => N;


export function visitNode<N>(
    node: N,
    visitor: Visitor<N>,
    lift?: (node: N[]) => N | undefined
): N | undefined {
    if (node === undefined) {
        // If the input type is undefined, then the output type can be undefined.
        return node;
    }
    const visited = visitor(node);

    let visitedNode: N | undefined;
    if (visited === undefined) {
        // If the visited node is undefined, then the visitor must have returned undefined,
        // so the visitor must have been declared as able to return undefined, so TOut must be
        // potentially undefined.
        return undefined;
    } else if (Array.isArray(visited)) {
        visitedNode = (lift || extractSingleNode)(visited)
    } else {
        visitedNode = visited;
    }

    return visitedNode;
}
export function visitNodes<N>(
    nodes: Array<N>,
    visitor: Visitor<N>,
    start?: number,
    count?: number
): N[] {
    if (nodes === undefined) {
        // If the input type is undefined, then the output type can be undefined.
        return nodes;
    }

    const length = nodes.length;
    if (start === undefined || start < 0) {
        start = 0;
    }

    if (count === undefined || count > length - start) {
        count = length - start;
    }

    return visitArray(nodes, visitor, start, count);
}
export function visitEachChild<N>(
    node: N | undefined,
    visitor: Visitor,
    //typescript 是一个visitTable
    eachChildFn: VisitEachChildFunction<N>,
    nodesVisitor: NodesVisitor = visitNodes,
    nodeVisitor: NodeVisitor = visitNode
): N | undefined {
    if (node == undefined) {
        return undefined;
    }
    return eachChildFn(node, visitor, nodesVisitor, nodeVisitor);
}

function visitArray<N>(
    nodes: Array<N>,
    visitor: Visitor<N>,
    start: number,
    count: number
) {
    let updated: Array<N> | undefined;
    const length = nodes.length;
    if (start > 0 || count < length) {
        updated = [];
    }
    // Visit each original node.
    for (let i = 0; i < count; i++) {
        const node = nodes[i + start];
        const visited = node !== undefined ? (visitor ? visitor(node) : node) : undefined;
        if (updated !== undefined || visited === undefined || visited !== node) {
            if (updated === undefined) {
                updated = nodes.slice(0, i);
            }
            if (visited) {
                if (Array.isArray(visited)) {
                    for (const visitedNode of visited) {
                        updated.push(visitedNode);
                    }
                }
                else {
                    updated.push(visited);
                }
            }
        }
    }
    if (updated) {
        return updated;
    }
    return nodes;
}

function extractSingleNode<T>(nodes: Array<T>): T | undefined {
    if (nodes.length > 1) {
        throw new Error("Too many nodes written to output.")
    }
    // return singleOrUndefined(nodes);
    return nodes.length === 1
        ? nodes[0]
        : undefined;
}



