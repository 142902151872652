import {Alipay, WechatPay} from "@react-vant/icons";
import {isPC, isIOS} from "@firefly/fly-defter";
import {PaymentMethod, PaymentMethods} from "@firefly/fly-payment";
import {PaymentState} from "src/typing";

export const UID_SESSION_STORAGE_KEY = "uid";
export const TRACE_URL_SESSION_STORAGE_KEY= "traceUrl";
export enum Doctypes {
    PAGE= "page",
    PAGE_TURN = "page/turn",
    PAGE_CONCAT = "page/concat",
    IMAGE = "image",
}
export const PAYMENT_METHODS: PaymentMethod[] = [
    PaymentMethods.WECHATPAY_JSAPI,
    PaymentMethods.WECHATPAY_H5,
    // PaymentMethods.ALIPAY_PAGE,
    // PaymentMethods.ALIPAY_WAP
];
export const PAYMENT_METHOD_CONFIG_MAP: {[key: string]: any} = {
    [PaymentMethods.WECHATPAY_JSAPI]: {
        icon: <WechatPay fill={"#08bf00"}/>,
        title: "微信支付"
    },
    [PaymentMethods.WECHATPAY_H5]: {
        icon: <WechatPay fill={"#08bf00"}/>,
        title: "微信支付"
    },
    [PaymentMethods.ALIPAY_PAGE]: {
        icon: <Alipay  fill={"#1ba7ff"}/>,
        title: "支付宝",
    },
    [PaymentMethods.ALIPAY_WAP]: {
        icon: <Alipay  fill={"#1ba7ff"}/>,
        title: "支付宝",
    },
};
export const DoctypeMap: Record<string, string> = {
    "image": "图片",
    "page/concat": "长页",
    "page/turn": "翻页",
}
export const DOCUMENT_TYPE_LABEL_MAP: Record<string, string> = {
    "image": "图片",
    "page/concat": "长页",
    "page/turn": "翻页",
}

export const PAYMENT_STATE_LABEL_MAP: Record<PaymentState, string> = {
    "UNPAID": "未付款",
    "EXECUTION": "执行中",
    "PAID": "已付款",
    "REFUNDED": "已退款",

}
// export enum WorkTypes {
//     LOGO = "LOGO",
//     POSTER = "POSTER",
//     INVITATION = "INVITATION"
// }

export const DEFAULT_COVER_SIZE = {
    width: 200,
    height: 200,
}

export enum TemplateTypes {
    TEXT= "TEXT",
    WORK = "WORK",
    BACKGROUND = "BACKGROUND",
    CLIP_PATH_IMAGE = "CLIP_PATH_IMAGE",
}
export enum PaymentStates {
    UNPAID = "UNPAID",
    EXECUTION = "EXECUTION",
    PAID = "PAID",
    REFUNDED = "REFUNDED",
}


function isEnabled(value: string | undefined) {
    if (typeof value === 'string') {
        return value === 'true';
    }
    return !!value;
}


export const GLOBAL = {
    footer: {
        enable: isEnabled(import.meta.env.VITE_APP_FOOTER_ENABLE)
    },
    paymentMethods: import.meta.env.VITE_APP_PAYMENT_METHOD ? import.meta.env.VITE_APP_PAYMENT_METHOD.split(',') : [],
    template: isPC() || isIOS() ? {
        usingNavigate: import.meta.env.VITE_APP_RESTRICT_TEMPLATE_USING_NAVIGATE ? import.meta.env.VITE_APP_RESTRICT_TEMPLATE_USING_NAVIGATE.split(',') : [],
        paymentAdvance: isEnabled(import.meta.env.VITE_APP_RESTRICT_TEMPLATE_PAYMENT_ADVANCE),
        paymentHidden: isEnabled(import.meta.env.VITE_APP_RESTRICT_TEMPLATE_PAYMENT_HIDDEN),
    }: {
        usingNavigate: import.meta.env.VITE_APP_TEMPLATE_USING_NAVIGATE ? import.meta.env.VITE_APP_TEMPLATE_USING_NAVIGATE.split(',') : [],
        paymentAdvance: isEnabled(import.meta.env.VITE_APP_TEMPLATE_PAYMENT_ADVANCE),
        paymentHidden: isEnabled(import.meta.env.VITE_APP_TEMPLATE_PAYMENT_HIDDEN),
    }
}