import {cloneDeep as clone} from "lodash-es";
import {
    Document,
    SGElement,
    Documents,
    GraphicElement,
    GridGraphicElement,
    FillStyleOptions,
    BackgroundProperties,
    Visitor,
    StaticMapElement,
    ImageElement,
    ClippathImageElement,
    TextElement
} from "@firefly/fly-design-core";
import {GraphicPathType, Color, SGProps, isColorFillStyleOptions} from "@firefly/fly-design-core";
import {DocumentVisit} from "@firefly/fly-design-core";
import {color2hex} from "./upgrade2";
import DocumentVisitor = DocumentVisit.DocumentVisitor;
// import {Documents2, DocumentVisit, Visitor2} from "@firefly/fly-design-core";


export default function upgrade<T extends Document = Document>(input: T): T {
    const upgradeElementVisitor: DocumentVisitor['element'] = function(element: SGElement) {
        const { as } = element;
        const properties: SGProps = element.properties;

        delete properties["identifier"];
        delete properties["rotate"];
        delete element["_zIndex"];
        delete element["attributes"];
        switch (as) {
            case "Image": {
                const props: ImageElement = properties as ImageElement;
                const {
                    content
                } = props;
                delete content['scale'];
                break
            }
            case "ClippathImage": {
                const props: ClippathImageElement = properties as ClippathImageElement;
                const {
                    content
                } = props;
                delete content['scale'];
                break
            }
            case "Text": {
                const props: TextElement = properties as TextElement;
                const {
                    style
                } = props;
                delete style["writingMode"]
                delete props["shape"]
                break;
            }
            case "GridGraphic": {
                const props : GridGraphicElement = properties as GridGraphicElement;
                const {
                    paths
                } = props;
                paths.forEach(function(path: GraphicPathType) {
                    let pathFill = path.fill;
                    if (pathFill) {
                        if (typeof pathFill === 'string') {
                            path.fill = {
                                type: "color",
                                color: pathFill!
                            };
                        }
                    }

                })
                break;
            }
            case "StaticMap": {
                const props : StaticMapElement = properties as StaticMapElement;
                const {
                    location
                } = props;
                if (location) {
                    props.location = {
                        lng: location.lng,
                        lat: location.lat
                    }
                }
                break;
            }
        }
        return element;
    };

    let document: Document = clone(input);
    delete document["fonts"]
    delete document["theme"]
    document = DocumentVisit.visit(document, {
        element: upgradeElementVisitor,
    })
    document.version = "1.2";
    return document as T;
}