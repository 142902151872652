
export type ResourceStatus = "loading" | "success" | "error";
export type ResourceFetchStatus = "fetching" | "paused" | "idle";

export type ResourceOptions<T> = {
  name?: string;
  load: () => Promise<T>;
};

export interface PromiseExecutorType<T> {

  resolve: (value: T) => void;

  reject: (reason?: any) => void;
}
export class Resource<T> {
  status: ResourceStatus = "loading";
  fetchStatus: ResourceFetchStatus = "idle";

  data?: T = undefined;
  error?: any;
  isCompleted!: boolean;


  _loadFn?: () => Promise<T>;

  _fetch: Promise<T> | null = null;
  _executor: PromiseExecutorType<T> | null = null;

  _destroyed: boolean = false;
  public constructor(
    options: ResourceOptions<T>
  ) {
    const {
      load
    } = options;
    this.isCompleted = false;
    this._loadFn = load;
  }

  // _load() {
  //   if (this.status === "success") {
  //     return Promise.resolve(this.data!);
  //   }
  //   return this.fetch();
  // }


  protected onSuccess(data: T) {
    if (this._destroyed) {
      this._destroy(data);
      return;
    }
    this.status = "success";
    this.data = data;
    this._executor!.resolve(data);
    this.onComplete();
  }
  protected onError(reason?: any) {
    if (this._destroyed) {
      return;
    }
    this.status = "error";
    this.error = reason;
    if (this._executor) {
      this._executor.reject(reason);
    }
    this.onComplete();
  }
  protected onComplete() {
    return (this.isCompleted = true);
  }

  // protected execute(): Promise<T> {
  //   let fetch = this._fetch;
  //   if (!this._fetch) {
  //
  //   }
  //   return fetch;
  // }

  private cancel() {
    if (this._executor) {
      this._executor.reject('cancel')
    }
  }

  // protected abstract onLoad(): Promise<T>;
  fetch(): Promise<T> {
    let fetch = this._fetch;
    if (!fetch) {
      fetch = this._fetch = new Promise((resolve, reject) => {
        this._executor = {
          resolve: resolve,
          reject: reject,
        };
        this._loadFn!().then(
            (data) => {
              return this.onSuccess(data)
            },
            (reason) => {
              return this.onError(reason)
            }
        ).finally(() => {
          this._executor = null;
          this._fetch = null;
        })
      })
    }
    return fetch;
  }

  load() {
    if (this.status === 'success') {
      return Promise.resolve(this.data);
    }
    return this.fetch();
  }

  get(): T | null {
    switch (this.status) {
      case "error": {
        return null;
      }
      case "success": {
        return this.data!;
      }
      case "loading": {
        throw this.fetch();
      }
    }
  }

  _destroy(data: T | undefined) {
    if (data && typeof data === 'object') {
      if ('destroy' in data && typeof data['destroy'] === 'function') {
        data.destroy()
      }
    }

  }
  public destroy() {
    this._destroy(this.data)
    if (this._executor) {
      this.cancel();
    }
    this.data = undefined;
    this._destroyed = true;
  }
}
