import classNames from "classnames";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {Link, useNavigate, useNavigationType, useParams} from "react-router-dom";
import DocumentTitle from "react-document-title";
import {HomeO} from "@react-vant/icons";
import {isIOS, isMobile, isMiniapp, isWechat, isWeapp} from "@firefly/fly-defter";
import {navigateBackMiniapp, Share} from "@firefly/fly-bridge";
import {isPageDocument, isImageDocument, isPageDoctype, isImageDoctype} from "@firefly/fly-design-core";
import {Invitations} from "@firefly/fly-design-inv-core";
import {ReactComponent as BackIcon} from "src/asset/nav/back.svg";
import ImageTemplateContent from "./ImageTemplateContent";
import {event} from "src/util/reporter";
import {useViewReport} from "src/hook/view";
import {useRelaunchToTemplates} from "src/suppport/router";
import useQueryParam from "src/hook/useQueryParam";
import { ReactComponent as StarIcon } from "src/asset/star.svg";
import useTemplate from "./useTemplate";
import { ReactComponent as StarFillIcon } from "src/asset/star-fill.svg";
import GuideAddMyWeappPopup from "src/page/component/GuideAddMyWeappPopup";
import useGuidance from "src/util/useGuidance";
import {Affix, TemplateIndicatorLayout} from "./styles";
import {
    TemplateBody,
    TemplateContainer,
    TemplateContent,
    Toolbar,
    ToolbarButton, ToolbarButtonText, ToolbarContent, ToolbarContentLeft, ToolbarContentRight,
} from "./components";
import {GLOBAL} from "../../../config";
import {useSecurity} from "../../../context/security";
import {Button} from "antd-mobile";
import Indicator from "src/component/Indicator";
import {Loading} from "@firefly/fly-kda";
import {TagEvent} from "../../../tag";
import {ISize} from "@stage/math";
// import PageTemplateContent from "./PageTemplateContent";
const PageTemplateContent = React.lazy(() => import("./PageTemplateContent"));
// const PageTemplateContent = React.lazy(() => import("./PageTemplateContent"));


const isNavigateToWeappTemplate =  isMobile() && !isMiniapp() && !isWechat() && !isIOS()
    && !!~GLOBAL.template.usingNavigate.indexOf('weapp');

interface TemplateProps {
    templateId: string;
}
const Template = Indicator.withSuspense(function Template({
    templateId,
}: TemplateProps) {

    const navigateType = useQueryParam('navigateType');
    const navigate = useNavigate();
    const {
        isViewerLatelyRefTemplateDesignLoading,
        isLoading,
        template,
        star,
        unstar,
        viewerHasStarred,
        requiredPayment,
        requiredAdvancePayment,
        enterDesign,
        enterPayment,
        enterWeappTemplate,
    } = useTemplate(templateId!, {

    });

    useEffect(() => {
        if (template?.title) {
            event('view_item', {
                'category': 'template',
                'label': `${template.title}`
            })
        }
    }, [template?.title])





    const [creating, setCreating] = useState<boolean>(false);

    const {
        requestAuthenticated
    } = useSecurity();

    function enter(type?: 'payment' | 'design') {
        requestAuthenticated().then(() => {
            if (typeof type === 'undefined') {
                type = requiredAdvancePayment ? 'payment' : 'design';
            }
            if (type === 'payment') {
                setCreating(true);
                enterPayment().then(() => {

                }).finally(() => {
                    setCreating(false)
                })
            }
            if (type === 'design') {
                setCreating(true);
                enterDesign().then(() => {

                }).finally(() => {
                    setCreating(false)
                })
            }
        }, () => {

        })

    }

    const cover = Invitations.shareCover(template?.preview, template?.coverBox);
    // const coverUrl = preview2share(template?.preview);

    const navigationType = useNavigationType();

    const backToHomepage = window.history.length <= 1;
    useViewReport(() => {
        return {
            label: `Template`,
            extra: {
                templateId: templateId!,
            }
        }
    }, [templateId])

    // const {
    //     isFetching: isConfigFetching,
    //     value: guide,
    // } = useConfigQuery('guide', () => {
    //     if (isAndroid()) {
    //         return {
    //             type: 'weapp'
    //         }
    //     } else {
    //         return {
    //             type: "none"
    //         }
    //     }
    // });


    // const guideTemplatePayment = useConfigValue(['view', 'guideTemplatePayment'])





    const [size, setSize] = useState<ISize>();

    const layoutRef = useCallback((element: HTMLDivElement | null) => {
        if (element) {
            setSize({
                width: element.offsetWidth,
                height: element.offsetHeight
            })
        }
    }, [])
    const doctype = template?.doctype;
    let content: ReactNode = null;


    //异步的去触发装载
    // const [enableWechatBridge, setEnabledWechatBridge] = useState<boolean>(false);
    // function onLoaded() {
    //     setEnabledWechatBridge(isWechat());
    // }
    if (template && isImageDoctype(template?.doctype)) {
        content = <ImageTemplateContent
            value={template!}
            // onLoaded={onLoaded}
        />;
    } else if (template && isPageDoctype(template?.doctype)){
        content = <PageTemplateContent
            key={`template-${template?.id}`}
            width={size?.width}
            height={size?.height}
            value={template}
            // transition={{timeout: 6000}}
            // onLoaded={onLoaded}
        />
    } else {
        //进行组件预加载
        content = <PageTemplateContent
            key={"placeholder"}
            width={size?.width}
            height={size?.height}
            value={undefined}
        />
    }

    useEffect(() => {
        if(template) {
            // const set = Documents.resolveFontFaceSets([template.document!])
            // console.log(`set`, set);
            TagEvent.view_item({
                id: template.id,
                // value: template.pricing?.amount??0,
            })
        }
    }, [template]);

    // useEffect(() => {
    //     if (template) {
    //         setEnabledWechatBridge(!!template && isWechat())
    //     }
    // }, [template])
    const [starHint, setStartHint] = useState<boolean>(false);
    const [enabledGuidance, disableGuidance] = useGuidance('weapp');
    const reaLunchToHomepageTemplates = useRelaunchToTemplates();
    return <DocumentTitle title={template?.title??"邀请函模板"}>
        <Share
            title={template?.title}
            description={"点击制作您的邀请函"}
            coverUrl={cover}
            link={window.location.href}
            enabled={!!template}
        >
            <TemplateContainer className={classNames("template", {
                "template--image": isImageDoctype(doctype),
                "template--page": isPageDoctype(doctype)
            })}>

                <GuideAddMyWeappPopup
                    visible={starHint && enabledGuidance && isWeapp()}
                    onClose={() => {
                        setStartHint(false)
                        disableGuidance();
                    }}
                />
                <>
                    <TemplateBody>
                        <TemplateContent ref={layoutRef}>
                            <Indicator.Suspense>
                                {content}
                                {isLoading ? <Indicator fullscreen /> : null}
                            </Indicator.Suspense>
                        </TemplateContent>
                    </TemplateBody>
                    <Affix visible={!isMiniapp() && !backToHomepage && navigationType === "POP"}>
                        <Link to={`/`} replace>
                            <HomeO width={30} height={30} color={"#000"} />
                        </Link>
                    </Affix>
                    <Toolbar>
                        <ToolbarContent>
                            <ToolbarContentLeft>
                                {isMiniapp() ? (navigateType  ? <>
                                        <ToolbarButton onClick={() => {
                                            if (navigateType === 'push') {
                                                return navigateBackMiniapp();
                                            } else {
                                                return reaLunchToHomepageTemplates();
                                            }
                                        }}>
                                            <BackIcon width={24} height={24} />
                                            <ToolbarButtonText>
                                                返回
                                            </ToolbarButtonText>
                                        </ToolbarButton>
                                </> : null) : <>
                                    {!backToHomepage && navigationType === "POP"  ? <>
                                        <ToolbarButton onClick={() => {
                                            navigate("/", {
                                                replace: true
                                            })
                                        }}>
                                            <HomeO width={24} height={24} />
                                            <ToolbarButtonText>
                                                返回
                                            </ToolbarButtonText>
                                        </ToolbarButton>
                                    </> : <>
                                        <ToolbarButton onClick={() => {
                                            navigate(-1)
                                        }}>
                                            <BackIcon width={24} height={24} />
                                            <ToolbarButtonText>
                                                返回
                                            </ToolbarButtonText>
                                        </ToolbarButton>
                                    </>}
                                </> }
                                <ToolbarButton disabled={isLoading} onClick={() => {
                                    if (template?.viewerHasStarred) {
                                        unstar().then();
                                    } else {
                                        setStartHint(true)
                                        star().then();
                                    }
                                }}>
                                    {viewerHasStarred ?
                                        <StarFillIcon fill={'#f3d500'} width={24} height={24} />:
                                        <StarIcon width={24} height={24} />
                                    }
                                </ToolbarButton>
                            </ToolbarContentLeft>
                            <ToolbarContentRight>
                                {isNavigateToWeappTemplate ? <>
                                        <div className={"template-toolbar-group"}>
                                            {/*{guideTemplatePayment ?*/}
                                            {/*    <ToolbarPrimaryButton loading={creating}*/}
                                            {/*                          background={"#ffc04e"}*/}
                                            {/*                          onClick={() => {*/}
                                            {/*                              enter(requiredPayment ? 'payment' : 'design');*/}
                                            {/*                          }}*/}
                                            {/*                          disabled={isLoading || creating}>*/}
                                            {/*        {requiredPayment ? <>*/}

                                            {/*            /!*立即使用*!/*/}
                                            {/*            购买模板*/}
                                            {/*            /!*￥{template?.pricing.amount}*!/*/}
                                            {/*        </> : "马上使用"}*/}
                                            {/*    </ToolbarPrimaryButton> : null*/}
                                            {/*}*/}
                                            <Button
                                                className={"template-primary-button"}
                                                onClick={enterWeappTemplate}
                                                disabled={isLoading || !template}
                                            >
                                                到微信打开模板
                                            </Button>
                                        </div>
                                    </> :
                                    <>
                                        <Button
                                            className={"template-primary-button"}
                                            loading={creating}
                                            onClick={() => {
                                                enter()
                                            }}
                                            disabled={
                                                isViewerLatelyRefTemplateDesignLoading ||
                                                isLoading || creating
                                            }
                                        >
                                            {requiredAdvancePayment ? <>
                                                立即使用
                                                {template?.pricing.amount ? <>
                                                ￥{template?.pricing.amount}
                                                </> : null}
                                            </> : "马上使用"}
                                        </Button>
                                    </>

                                }
                            </ToolbarContentRight>
                        </ToolbarContent>
                    </Toolbar>
                </>
            </TemplateContainer>
        </Share>
    </DocumentTitle>
});

function TemplatePage() {
    const {
        templateId,
    } = useParams();
    return <Indicator.Suspense>
        <Template templateId={templateId!} />
    </Indicator.Suspense>
}

interface TemplatePresentProps {
    templateId: string;
    visible?: boolean;
}
function TemplatePresent(props: TemplatePresentProps) {
    const {
        templateId,
        visible,
        ...rest
    } = props;
    return <div className={classNames("present-modal", {
        "modal-visible": visible
    })}>
        <Template templateId={templateId} />
    </div>
}

TemplatePage.present = function(config: TemplatePresentProps) {

    const div = document.createElement('div');
    document.body.appendChild(div);

    const templateId = config.templateId;
    let currentConfig = { ...config, close, visible: true } as any;

    function destroy() {
        const unmount = ReactDOM.unmountComponentAtNode(div);
        if (unmount && div.parentNode) {
            div.parentNode.removeChild(div);
        }

    }
    function render({templateId, ...props}: any) {
        //Sync render blocks React event. Let's make this async.
        setTimeout(() => {
            ReactDOM.render(
                <TemplatePresent {...props} templateId={templateId} />,
                div,
            )
        })
    }
    function close() {
        currentConfig = {
            ...currentConfig,
            visible: false,
            // afterClose
            // afterClose:
        }
        render(currentConfig);
    }

    function update(newConfig: TemplatePresentProps) {
        currentConfig = {
            ...currentConfig,
            ...newConfig
        }
        render(currentConfig)
    }
    render(currentConfig);


    return {
        destroy: close,
        update,
    }

    // window.history.pushState({}, "", `/templates/${templateId}`)
    // Dialog.show({
    //     message: <Template
    //         templateId={templateId}
    //     />
    // })
}

export default TemplatePage;

// export default function()  {
//
//     return <Popup visible={true}>
//         <WrapTemplate />
//     </Popup>
// }
// export default WrapTemplate;
