import React, {Suspense, useEffect, useState} from 'react';
import {
    BrowserRouter,
    Navigate,
    Route,
    RouteProps,
    Routes,
    useLocation,
    useNavigation,
    useRoutes,
} from 'react-router-dom'
import {WechatAppBridge, WechatSDK, WechatMenuConfig} from "@firefly/fly-bridge";
import {PaymentClientProvider} from "@firefly/fly-payment";
import themeVars from "./vant-theme";
import {RestorationProvider} from "./restoration";
import ViewerQueryClientProvider from "./context/ViewerQueryClientProvider";
import {SecurityContext} from "./context/security";
import Homepage from './page/Homepage';
import Me from "./page/Homepage/Me";
import IndexPage from "./page/Homepage/IndexPage";
import Templates from "./page/Homepage/Templates";
import OrderPayment from "./page/payment/OrderPayment";
import Login from "./page/passport/Login";
import Search from "./page/search/Search";
import SearchResult from "./page/search/SearchResult";
import MeStarredTemplates from "./page/me/MeStarredTemplates";
import MeProfile from "./page/me/MeProfile";
import Template from "./page/template/Template";
import PageInspect from './page/page/PageInspect';
import {VConsoleSDK} from './lib/sdk';
import SEMTraceProvider from "./context/SEMTraceProvider";
import useQueryParam from "./hook/useQueryParam";
import Contact from "./page/about/Contact";
import AboutUs from './page/about/AboutUs';
import HelpCenter from "./page/service/help/HelpCenter";
import VipHelpCenter from "./page/service/help/VipHelpCenter";
import ContactDesigner from "./page/service/help/ContactDesigner";
import PVip from "./page/vip/PVip";
import TemplatePayment from "./page/template/TemplatePayment";
import MakePayment from "./page/payment/MakePayment";
import ImageInspect from "./page/design/ImageInspect";
import PageSharePoster from "./page/page/PageSharePoster";
import TemplateMakePayment from "./page/template/TemplateMakePayment";
import MePurchasedTemplates from "./page/me/MePurchasedTemplates";
import MeOrders from "./page/me/MeOrders";
import LoginExpired from "./page/passport/LoginExpired";
import OrderComplete from "./page/payment/OrderComplete";
import TrackingProvider from "./trace/TrackingProvider";
import SecuredPageBoundary from "src/security/SecuredPageBoundary";
import {SecurityAppClientProvider} from "src/context/security";
import {Button, ErrorBlock, Toast} from "antd-mobile";
import SendList from "./page/design/SendList";
import CreateSend from "./page/design/CreateSend";
import SendDetail from "./page/design/SendDetail";
import {BridgedContext} from "@firefly/fly-security";
import Logout from "./page/passport/Logout";
import {values} from "./util/values";
import {error} from "./util/reporter";
import DOMLaunch from "./solve-app/DOMLaunch";
import WebappConfigProvider from "./context/webapp/WebappConfigProvider";
import {ErrorBoundary} from "react-error-boundary";
import {report} from "src/trace/TraceKit";
import {isMessageError} from "@firefly/fly-http";
import "./style/app.scss";
import isTrue = values.isTrue;

export interface AppProps {
    wrapped?: boolean;
}


function App(props: AppProps) {
    const {
        wrapped
    } = props;

    const SNSBridgeContext = !wrapped ? WechatAppBridge : React.Fragment;
    return (
        // <SNSBridgeContext enabled={true} configKey={[location.pathname]}>
        <SNSBridgeContext enabled={true}
            onError={(reason) => {
                error("SNSBridge.config", reason)
            }}
        >
            {/*<Flex>*/}
            {/*<Button onClick={() => {*/}
            {/*    config()*/}
            {/*}}>*/}
            {/*    配置设置*/}
            {/*</Button>*/}
            {/*</Flex>*/}
            <Routes>
                <Route path={"/homepage"} element={
                    <WechatMenuConfig>
                        <Homepage/>
                    </WechatMenuConfig>
                }>
                    <Route path={"/homepage/index"} element={ <IndexPage/> }/>
                    {/*<Route path={"/homepage/me-work"} element={<Authenticated>*/}
                    {/*  <MeWorks />*/}
                    {/*</Authenticated>} />*/}
                    <Route path={"/homepage/templates"} element={ <Templates/> }/>
                    <Route path={"/homepage/me"} element={ <Me/> }/>
                    <Route path={"/homepage"} element={ <Navigate to={"/homepage/index"} replace /> }/>
                </Route>
                <Route path={"/search"} element={
                    <Search/>
                }/>
                <Route path={"/search/:keyword"} element={
                    <SearchResult/>
                } />
                <Route path={"/templates/:templateId"} element={
                    <WechatMenuConfig>
                        <Template/>
                    </WechatMenuConfig>
                }/>
                <Route path={"/templates/:templateId/payment"} element={
                    <SecuredPageBoundary>
                        <TemplatePayment/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/templates/:templateId/make-payment"} element={
                    <SecuredPageBoundary>
                        <TemplateMakePayment/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/payment/make-payment"} element={
                    <SecuredPageBoundary>
                        <MakePayment/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/orders/:id/complete"} element={
                    <SecuredPageBoundary>
                        <OrderComplete/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/order-payment"} element={
                    <SecuredPageBoundary>
                        <OrderPayment />
                    </SecuredPageBoundary>
                }/>
                <Route path={"/invitation/pages/:id/inspect"} element={
                    <WechatMenuConfig>
                        <PageInspect/>
                    </WechatMenuConfig>
                }/>
                <Route path={"/invitation/pages/:id/share-poster"} element={
                    <WechatMenuConfig>
                        <PageSharePoster/>
                    </WechatMenuConfig>
                }/>
                <Route path={"/designs/:id/image-inspect"} element={
                    <SecuredPageBoundary>
                        <ImageInspect/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/designs/:designId/sends"} element={
                   <SecuredPageBoundary>
                       <SendList />
                   </SecuredPageBoundary>
                } />
                <Route path={"/designs/:designId/sends/create"} element={
                    <SecuredPageBoundary>
                        <CreateSend />
                    </SecuredPageBoundary>
                } />
                <Route path={"/designs/:designId/sends/:id"} element={
                    <SecuredPageBoundary>
                        <SendDetail />
                    </SecuredPageBoundary>
                } />
                <Route path={"/me/profile"} element={
                    <SecuredPageBoundary>
                        <MeProfile/>
                    </SecuredPageBoundary>
                }/>

                <Route path={"/me/starred-templates"} element={
                    <SecuredPageBoundary>
                        <MeStarredTemplates/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/me/purchased-templates"} element={
                    <SecuredPageBoundary>
                        <MePurchasedTemplates/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/me/orders"} element={
                    <SecuredPageBoundary>
                        <MeOrders/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/help-center"} element={
                    <HelpCenter/>
                }/>
                <Route path={"/viphelp-center"} element={
                    <VipHelpCenter/>
                }/>
                <Route path={"/contact"} element={
                    <Contact/>
                }/>
                <Route path={"/contact-designer"} element={
                    <ContactDesigner/>
                }/>

                <Route path={"/aboutus"} element={
                    <AboutUs/>
                }/>
                <Route path={"/pvip"} element={
                    <SecuredPageBoundary>
                        <PVip/>
                    </SecuredPageBoundary>
                }/>
                <Route path={"/login"} element={
                    <Login/>
                }/>
                <Route path={"/login-expired"} element={
                    <LoginExpired/>
                }/>
                <Route path={"/logout"} element={
                    <Logout />
                }/>
                <Route path={"/"} element={
                    <Navigate to={"/homepage/index"} replace />
                }/>
            </Routes>
        </SNSBridgeContext>
    );
}

export default function AppWrapper() {

    const isDebug = values.isTrue(useQueryParam('debug'));
    const wrapped: boolean = values.isTrue(useQueryParam("wrapped"));
    // const debug: string | null = useSearchParam("debug");
    React.useEffect(() => {
        if (isDebug) {
            VConsoleSDK.init().then(() => {
                console.log("vconsole loaded")
            })
        }
        if (window.loadingSpinner) {
            window.loadingSpinner.hide();
        }

        if (isTrue(process.env.VITE_APP_PREVIEW)) {
            Toast.show({
                content: "当前为预览版"
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <ErrorBoundary
        onError={(error, info) => {
            if (process.env.NODE_ENV !== 'development') {
                report(error);
            }

        }}
        // fallback={<>
        // </>}
        fallbackRender={({error, resetErrorBoundary}) => {
            // console.warn(error);
            let title = `加载异常，请重试或联系客服`;
            if (isMessageError(error)) {
                const {
                    errorInfo
                } = error;
                const message = errorInfo.message??errorInfo.code;
                title = `加载异常：${message}`;
            }
            return <div className={"error-fallback"}>
                <ErrorBlock
                    style={{
                        "--image-height": "160px",
                    }}
                    image={
                        "https://img.yzcdn.cn/vant/empty-image-default.png"
                    }
                    status={"empty"}
                    title={title}
                    description={"待会再来试试"}
                    // status={"empty"}
                    // description={description}
                >
                    <Button
                        className={"fly-button-primary"}
                        color={"primary"}
                        style={{width: 200, borderRadius: 22}}
                        onClick={() => {
                            resetErrorBoundary();
                        }}
                    >
                        刷新
                    </Button>
                </ErrorBlock>
            </div>
        }}
    >
        <DOMLaunch>
            <SecurityAppClientProvider>
                <PaymentClientProvider>
                    <ViewerQueryClientProvider>
                        <WebappConfigProvider>
                            <SecurityContext>
                                <SEMTraceProvider>
                                    <RestorationProvider>
                                        <BrowserRouter>
                                            <App wrapped={wrapped}/>
                                        </BrowserRouter>
                                    </RestorationProvider>
                                </SEMTraceProvider>
                            </SecurityContext>
                        </WebappConfigProvider>
                    </ViewerQueryClientProvider>
                </PaymentClientProvider>
            </SecurityAppClientProvider>
        </DOMLaunch>
    </ErrorBoundary>
    // return <ConfigProvider themeVars={themeVars} style={{
    //     width: `100%`,
    //     height: `100%`,
    // }}>
    //
    // </ConfigProvider>
};

