import {useCallback, useMemo} from "react";
import Cookies from "js-cookie";
import {useGetSet} from "@firefly/fly-hook";

const GUIDANCE_FLAG = 'y';
/**
 *
 */
function useGuidance(key: string): [boolean, () => void] {
    const [name, initialState]  = useMemo(() => {
        const encodedKey = `guidance_${encodeURIComponent(key)}`;
        return [encodedKey, GUIDANCE_FLAG !== Cookies.get(encodedKey)];
        // eslint-disable-next-line
    }, []);
    const [getEnabled, setEnabled] = useGetSet<boolean>(initialState);

    const disable = useCallback(() => {
        setEnabled(false)
        Cookies.set(name, GUIDANCE_FLAG, {
            expires: 30
        });
    }, [name, setEnabled]);
    const enabled: boolean = getEnabled();
    return [enabled, disable]
}

export default useGuidance;