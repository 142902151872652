
import {GradientOptions} from "./gradient";
import {Color, IBox} from "./basic";


/**
 * @obfuscator Element
 */
interface ImagePatternTransform {
    scaleX: number;
    scaleY: number;
}
/**
 * @obfuscator Element
 */
export interface ImagePattern {
    src: string;
    box: IBox;
    //保持长宽比例进行填充
    preserveAspectRatio?: true;
    transform?: ImagePatternTransform
}
/**
 * stage graphic
 * @obfuscator Element
 */
export interface BasicFillOptions {
    type: string;
}

/**
 * @obfuscator Element
 */
export interface ColorFillOptions extends BasicFillOptions{
    type: 'color';
    color: Color;
}

/**
 * @obfuscator Element
 */
export interface GradientFillOptions extends BasicFillOptions {
    type: 'gradient';
    gradient: GradientOptions;
}
/**
 * @obfuscator Element
 */
export interface PatternFillOptions extends BasicFillOptions {
    type: 'pattern';
    pattern?: ImagePattern;
}
export type FillStyleOptions = ColorFillOptions | PatternFillOptions | GradientFillOptions;

export type StrokeStyleOptions = ColorFillOptions | GradientFillOptions;

export type StrokeWidthType = number;

export type StrokeDasharrayType = [number, number];


export function isColorFillStyleOptions(value: FillStyleOptions): value is ColorFillOptions {
    return value.type === 'color';
}

// export interface StrokeStyleOptions {
//     fill: StrokeFillOptions;
//     width: number;
//     dasharray?: [number, number];
// }

