import {IRectangle, ISize} from "@stage/math";
import {ImageProcess} from "./image";
import {Picture} from "../typing/invt";

export function picture2cover(url: string, size: ISize): string {
    return `${url}?x-oss-process=image/resize,w_${size.width}/crop,h_${size.height}/format,jpg/quality,q_80`
}
export namespace Invitations {

    export interface LinkOptions {
        /**
         * 默认当前 location.origin
         */
        origin?: string;

        /**
         * 发送给
         */
        send?: string | null

        /**
         * 嵌套内
         */
        wrapped?: boolean;

        debug?: boolean;
    }
    export function link(pageId: string, options: LinkOptions) {
        const {
            origin = window.location.origin,
            send,
            wrapped,
            debug
        } = options;
        const params = new URLSearchParams();
        if (send) {
            params.set("send", send);
        }
        if (wrapped) {
            params.set('wrapped', 'true');
        }
        if (debug) {
            params.set('debug', 'true');
        }
        return `${origin}/invitation/p/${pageId}` + (params.size > 0 ? `?${params.toString()}` : '');
    }
    export function shareCover(preview: Picture | undefined, crop?: IRectangle | null): string | undefined {
        if (preview) {
            if (!crop) {
                const size = Math.min(preview.width, preview.height)
                crop = {
                    x: Math.round((preview.width - size)/2),
                    y: Math.round((preview.height - size)/2),
                    width: Math.round(size),
                    height: Math.round(size),
                }
            }
            return ImageProcess.process(preview.url, {
                crop: crop,
                resize: {
                    width: 120,
                },
                quality: 80,
            })
        }
        return undefined;
        // return picture2cover(url, {
        //     width: 120,
        //     height: 120,
        // })
        // return `${url}?x-oss-process=image/resize,w_120/crop,h_120/format,jpg/quality,q_80`;
    }
}

