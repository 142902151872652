import {Resource} from "./Resource";
import {ResolveResource} from "./typing";
import {Resources} from "./Resources";

// export interface AssetLoader<T, A = Asset> {
//     test(asset: Asset): boolean;
//     load(asset: A, args: Record<string, string>): Promise<T>;
// }


export abstract class ResourceLoader<T = any, R extends Resource<T> = Resource<any>> {

    schema: RegExp;

    protected constructor(schema: RegExp | string) {
        let regexp: RegExp;
        if (typeof schema === 'string') {
            regexp = new RegExp(`^${schema}`)
        } else {
            regexp = schema;
        }
        this.schema = regexp;
    }

    public test(uri: string, resolve?: ResolveResource, resources?: Resources): boolean {
        return this.schema.test(uri);
    }
    abstract load(uri: string, resolve: ResolveResource, resources: Resources): R;
}

