
import {queryStringify} from "@firefly/fly-design-util";
import {ISize} from "@stage/math";
import {LngLat} from "../typing";
import {Resource} from "./Resource";
import {ResourceLoader} from "./Loader";
import {ResolveResource} from "./typing";
import {Resources} from "./Resources";
import {buildStaticMapUrl} from "../util/map";


export interface MapTarget {
    //定位
    location: LngLat,
    //缩放
    zoom?: number,
    //大小
    size: ISize;
}

export class MapResource extends Resource<any>{

    constructor(
        load: () => Promise<any>
    ) {
        super({
            load: load
        });
    }
}
export class MapResourceLoader extends ResourceLoader<any> {

    constructor() {
        super("map:");
    }
    load(uri: string, resolve: ResolveResource, resources: Resources): MapResource {
        const query = uri.substring(uri.indexOf('?'));
        const params = new URLSearchParams(query);
        const center = params.get('center')!;
        const zoom = params.get('zoom')!;
        const size = params.get('size')!;
        const lnglat = center.split(',');
        const whSize = size.split('*')

        const url = buildStaticMapUrl(
            {
                lng: Number(lnglat[0]),
                lat: Number(lnglat[1])
            },
            {
                width: Number(whSize[0]),
                height: Number(whSize[1])
            },
            Number(zoom)
        )
        return new MapResource(async function() {
            return resources.assets.load({
                src: url,
                format: "image"
            })
        });
    }


    static resolve(target: MapTarget): ResolveResource {

        return {
            uri: `map:staticmap?${queryStringify({
                center: `${target.location.lng},${target.location.lat}`,
                size: `${target.size.width}*${target.size.height}`,
                zoom: `${target.zoom}`,
            })}`
        }
    }
}