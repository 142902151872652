import {ImageProcess} from "./image";

const cnameMapping: Record<string, string> = {
    ['firefly-resource']: "res.i-firefly.com",
    ['firefly-beast']: `oss.i-firefly.com`,
    ['firefly-asset']: `asset.i-firefly.com`,
}
const cdnCNameMapping: Record<string, string> = {
    ['firefly-resource']: "res.i-firefly.com",
    ['firefly-beast']: `o.i-firefly.com`,
    ['firefly-asset']: `a.i-firefly.com`,
}

export interface Blob {
    bucket: string;
    key: string;
    mimetype?: string;
}


export namespace ObjectURL {

    export function createURL(blob: Blob): string {
        const {
            bucket,
            key
        } = blob;
        const name = cnameMapping[bucket]??bucket;
        return `https://${name}/${key}`;
    }
    /**
     * 创建优化URL
     * @param blob
     */
    export function createOptimizationURL(blob: Blob): string {
        const {
            mimetype,
            bucket,
            key
        } = blob;
        const name = cdnCNameMapping[bucket]??bucket;
        const url = `https://${name}/${key}`;
        if (mimetype === 'image/heic') {
            return ImageProcess.process(url, {
                format: "jpg",
                quality: 80,
            })
        }
        return url;
    }
}

