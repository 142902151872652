import {IRectangle, ISize} from "@stage/math";


export namespace ImageProcess {
    interface ProcessOptions {

        crop?: IRectangle;

        resize?: {
            mode?: string
        } & Partial<ISize>;

        format?: string;
        /**
         * 0 - 100
         */
        quality?: number;
    }
    export function process(url: string, options: ProcessOptions) {
        const process: string[] = [];
        let {
            crop,
            resize,
            quality,
            format
        } = options;
        if (quality) {
            if (!format) {
                format = 'jpg';
            }
        }
        function cmd(...args: (string | undefined | 0 | false)[]) {
            process.push("/" + args.filter((args) => {
                return !!args
            }).join(','));
        }
        if (crop) {
            cmd("crop", `x_${Math.round(crop.x)}`, `y_${Math.round(crop.y)}`, `w_${Math.round(crop.width)}`, `h_${Math.round(crop.height)}`)
        }
        if (resize) {
            const {
                mode,
                width,
                height
            } = resize;
            const args: string[] = [];
            if (mode) {
                args.push(`m_${mode}`)
            }
            if (width) {
                args.push(`w_${Math.round(width)}`)
            }
            if (height) {
                args.push(`h_${Math.round(height)}`)
            }
            cmd("resize", ...args)
        }
        if (format) {
            cmd("format", format)
        }
        if (quality) {
            cmd("quality", `q_${quality}`)
        }

        if (process.length > 0) {
            return `${url}?x-oss-process=image${process.join(',')}`
        }
        return url;
    }

}