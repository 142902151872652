import { Document, Documents } from "@firefly/fly-design-core";
import upgrade1 from "./v1/upgrade1";
import upgrade2 from "./v1/upgrade2";
import upgrade2_1 from "./v1/upgrade2-1";
import upgrade3 from "./v1/upgrade3";

export function upgrade<T extends Document = Document>(document: T): T {
  if (!document.version || document.version < "1.1") {
    document = upgrade1(document);
  }
  if (document.version < "1.2") {
    document = upgrade2(document);
  }
  //TODO 临时修补
  if (document.version === "1.2") {
    //进行修补
    document = upgrade2_1(document);
  }
  if (document.version < "1.3") {
    document = upgrade3(document)
  }
  return document;
}
