import {Document, DocumentVisit, ShapePath, ShapeSGElement} from "@firefly/fly-design-core";


export default function upgrade<T extends Document = Document>(input: T): T {


    const document = DocumentVisit.visit(input, {
        element: {
            Shape: (element: ShapeSGElement) => {

                const {
                    properties
                } = element;

                const {
                    zoom = 1,
                    paths,
                    ...rest
                } = properties as any;
                return {
                    ...element,
                    properties: {
                        ...rest,
                        paths: paths.map((path: ShapePath) => {
                            return {
                                ...path,
                                strokeWidth: path.strokeWidth ? Math.round(path.strokeWidth * zoom) : undefined,
                                round: path.round ? Math.round(path.round * zoom) : undefined
                            }
                        })
                    }
                }
            }
        }
    })
    document.version = "1.3"
    return document;
}