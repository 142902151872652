// import { Visitor } from "@firefly/fly-painter-util/es/node";
import {clone} from "lodash-es";
import {
  Document,
  SGElement,
  Documents,
  Visitor,
  DocumentVisit,
  isPageDocument,
  isImageDocument, isElementDocument, isMaternalDocument
} from "@firefly/fly-design-core";
// import { Document, Element } from "../typing/doc";
// import {
//   Documents,
//   isElementDocument,
//   isImageDocument,
//   isMaternalDocument,
//   isPageDocument,
// } from "../util/document";
import { upgradeAnimation } from "./upgrade0";
import DocumentVisitor = DocumentVisit.DocumentVisitor;

export const upgradePageElementVisitor: DocumentVisitor = {
  element: {
    enter: (element: SGElement) => {
      const as = element.as as string;
      const properties: any = element.properties;
      delete properties._zIndex;
      if (as === "ClipPathImage") {
        element.as = "ClippathImage" as any;
      }
      if (properties.animations) {
        const animations = properties.animations
          .map((animation: any) => {
            const { type, name, args } = animation;
            const output = upgradeAnimation(animation);
            if (!output) {
              console.error(
                `upgrade animation[${type} ${name} ${args}] failure`
              );
            }
            return output;
          })
          .filter((item: any) => {
            return !!item;
          });
        properties.animations = animations;
      }
    },
  },
};
export default function upgrade1<T extends Document = Document>(input: T): T {
  const upgradeClippathVisitor: DocumentVisitor = {
    element: {
      enter: (element: SGElement) => {
        // const { as } = element;
        const as: string  = element.as;
        const properties: any = element.properties;
        delete properties._zIndex;
        delete properties.identifier;
        if (as === "ClipPathImage") {
          element.as = "ClippathImage" as any;
        }

      },
    },
  };

  let document = clone(input);
  if (isPageDocument(document)) {
    // if (!isPageLayoutDocument(document)) {
    // const { envelope } = document;
    // if (envelope) {
    //   Elements.visit(envelope.elements, upgradePageElementVisitor);
    // }
    // }
    delete (document as any).envelope;
    document = DocumentVisit.visit(document, upgradePageElementVisitor);
    document.version = "1.1";
  } else if (isImageDocument(document)) {
    document = DocumentVisit.visit(document, upgradeClippathVisitor);
    document.version = "1.1";
  } else if (isElementDocument(document)) {
    document = DocumentVisit.visit(document, upgradeClippathVisitor);
    document.version = "1.1";
  } else if (isMaternalDocument(document)) {
    document = DocumentVisit.visit(document, upgradeClippathVisitor);
    document.version = "1.1";
  }
  return document;
}
