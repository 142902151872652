import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import "./polyfill";
import "src/style/global.scss";
import App from "./App";


//严格模式会执行2次 确保开发模式下可靠
const root = createRoot(document.getElementById('root')!);
root.render(
    <StrictMode>
        <App />
    </StrictMode>,
)
