import {
    VideoSGElement,
    ImageSGElement,
    ClippathImageSGElement,
    ShapeSGElement,
    TextSGElement,
    isDocument,
    isPageTurnDocument,
    isPageConcatDocument,
    BitmapTextSGElement,
    SGElement,
    GraphicSGElement,
} from "../document";
import {FontFaceType} from "../font";
import {DocumentVisit, DocumentVisitSourceType, ElementVisit} from "./visitor";



// export type BackgroundVisitor = (background: BackgroundProperties | undefined | null) => void;
// export interface DocumentVisitor {
//     background?: BackgroundVisitor;
//     element?: VisitorNode<SGElement>
// }

export namespace Documents {



    // export function visit(
    //     source: DocumentVisitSourceType,
    //     visitor: DocumentVisit.DocumentVisitor
    // ) {
    //     return DocumentVisit.visit(source, visitor)
    // }

    // export function visitElement(
    //     source: DocumentVisitSourceType,
    //     visitor: ElementVisitor
    // ): DocumentVisitSourceType | undefined {
    //
    //     return DocumentVisit.visit(source, {
    //         element: visitor
    //     });
    // }

    type TextVisitor = ElementVisit.VisitElementEnterFn<BitmapTextSGElement | TextSGElement>;
    export function visitTextElement(source: DocumentVisitSourceType, visitor: TextVisitor) {
        return DocumentVisit.visit(source, {
            element: {
                Text: visitor,
                BitmapText: visitor,
                EffectText: visitor,
            }
        })
    }

    export interface FontFaceVisitor {
        text: (text: string) => string;
    }
    export function visitFontFaceSets(
        source: DocumentVisitSourceType | DocumentVisitSourceType[],
        visitor: FontFaceVisitor
    ): FontFaceType[] {
        let sources: DocumentVisitSourceType[] ;
        if (!Array.isArray(source)) {
            sources = [source];
        } else {
            sources = source;
        }
        const familyCodepointMap: Record<string, Set<string>> = {};
        function append(family: string, text: string) {
            if (!family) {
                return;
            }
            const set = familyCodepointMap[family] || (familyCodepointMap[family] = new Set<string>());
            for (const codePoint of Array.from(text)) {
                set.add(codePoint);
            }
        }
        function visitText(element: TextSGElement | BitmapTextSGElement) {
            const {
                properties: {
                    text,
                    style
                }
            } = element;
            if (text) {
                const resolved = visitor.text(text);
                if (style.fontFamily && resolved) {
                    append(style.fontFamily, resolved);
                }
            }

        }
        for (const item of sources) {
            visitTextElement(item, visitText);
        }


        //空格也是有字形的
        // const ignoreRegex = /\s/g;
        const fontFaces: FontFaceType[] = [];
        for (let family in familyCodepointMap) {
            const codePoints = Array.from(familyCodepointMap[family]!);
            if (codePoints.length > 0) {
                codePoints.sort();
                fontFaces.push({
                    family: family,
                    codePoints: codePoints,
                });
            }
        }

        return fontFaces;
    }


    export type SourceVisitor = (source: string, format?: 'music' | 'image' | 'svg') => void;
    export type ElementSourceVisitor = (source: string, element: SGElement) => void;
    export function elementSourceVisitor(visitor: ElementSourceVisitor): ElementVisit.Visitor {
        function visitContentSource(element: VideoSGElement | ImageSGElement | ClippathImageSGElement) {
            const {
                properties: {
                    content
                }
            } = element
            if (content?.src) {
                visitor(content?.src, element);
            }
        }
        return {
            Video: visitContentSource,
            Image: visitContentSource,
            ClippathImage: visitContentSource,
            Graphic: (element: GraphicSGElement) => {
                const {
                    properties: {
                        url
                    }
                } = element;
                if (url) {
                    visitor(url, element);
                }

            },
            Shape: (element: ShapeSGElement) => {
                const {
                    properties: {
                        paths
                    }
                } = element;
                for (let path of paths) {
                    const fill = path.fill;
                    if (fill?.type === 'pattern') {
                        const {
                            pattern
                        } = fill;
                        if (pattern) {
                            const src = pattern.src;
                            if (src) {
                                visitor(src, element);
                            }
                        }
                    }
                }
            }
        }
    }
    export interface SourcesVisitor {
        music?: SourceVisitor;
        background?: SourceVisitor;
        element?: ElementSourceVisitor;
    }

    export function visitSource(source: DocumentVisitSourceType | DocumentVisitSourceType[], visitor: SourcesVisitor) {
        if (Array.isArray(source)) {
            for (let item of source) {
                visitSource(item, visitor);
            }
            return;
        }
        if (isDocument(source) && (isPageTurnDocument(source) || isPageConcatDocument(source))) {
            const music = source.backgroundMusic;
            if (music?.src) {
                visitor.music?.(music.src);
            }
        }
        DocumentVisit.visit(source, {
            background: (background) => {
                if (background?.image?.url) {
                    visitor.background?.(background.image.url);
                }
                return background??undefined
            },
            element: visitor.element ? elementSourceVisitor(visitor.element) : undefined
        })
    }

    export type AssetVisitor = (assetId: string) => void;
    export function visitAsset(source: DocumentVisitSourceType | DocumentVisitSourceType[], visitor: AssetVisitor) {
        function resolveAsset(source: string) {
            if (source && source.startsWith('asset:')) {
                const asset = source.substring(6);
                visitor(asset);
            }
        }
        return visitSource(source, {
            music: resolveAsset,
            background: resolveAsset,
            element: resolveAsset
        })
    }

}
